import {Avatar, Box, Button, Grid, Hidden, Typography,} from '@mui/material';
import {Link} from 'react-router-dom';
import React from 'react';
import CTAButton from '../components/CTAButton';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';

function About() {
  return <>
    <Box style={{ overflowX: 'hidden' }}>
      {/* Top section  */}
      <Box mt="100px" width="100%">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={6} sm={10}>
            <Typography variant="h1">
              <Box
                fontWeight="400"
                color="#444444"
                style={{ lineHeight: '4.5rem' }}
                className="aboutHeading"
              >
                Specialty enrollment is{' '}
                <b style={{ fontWeight: 700 }}>complex.</b> <br /> We make it{' '}
                <b style={{ fontWeight: 700 }}>simple.</b>
              </Box>
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item align="center" lg={2} md={3} sm={12}>
              <img
                src="https://cdn.rxlightning.com/images/heart-bolt.png"
                height="350"
                alt="rxlightning heart bolt"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      {/* Mission section  */}
      <Box mt={8} mx="auto" width="100%" py="150px" className="missionMargin">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item align="left" md={5} sm={4} xs={10}>
            <Box
              fontWeight="600"
              fontSize="2.5rem"
              color="#444444"
              className="specialtySectionTitle"
            >
              Our Mission
            </Box>
            <Typography
              variant="body1"
              textAlign="center"
              style={{ color: '#444444' }}
            >
              <Box width="72%" className="socialSection">
                <p>
                  To create a world where every patient gets accelerated
                  access to the therapies they need, through the creation of a
                  single destination for specialty prescriptions that makes
                  the enrollment process easy, automated and as seamless as
                  possible.
                </p>
              </Box>
            </Typography>
            <Box
              fontWeight="600"
              fontSize="2.5rem"
              color="#444444"
              className="specialtySectionTitle"
            >
              Our Vision
            </Box>
            <Typography
              variant="body1"
              textAlign="center"
              style={{ color: '#444444' }}
            >
              <Box width="72%" className="socialSection">
                <p>
                  To transform the specialty prescription enrollment process
                  so that it's never a barrier to life-altering therapies.
                </p>
              </Box>
            </Typography>
          </Grid>
          <Grid item align="center" md={4} xs={12}>
            <img
              src="https://cdn.rxlightning.com/images/website/fortheone.png"
              width="100%"
              alt="boy getting a shot at doctor"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Value section  */}
      <Box width="100%" py="100px" mx="auto" className="missionMargin">
        <Box
          fontWeight="600"
          fontSize="2.5rem"
          color="#444444"
          textAlign="center"
        >
          Our Values
        </Box>
        <Box mt="100px">
          <Grid
            container
            direction="column"
            spacing={6}
            className="socialSection"
          >
            <Grid item className="valueItem">
              <Grid container direction="row" justifyContent="center" spacing={6}>
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/icons/ethical.svg"
                    width="200"
                    height="200"
                    alt="placeholder"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box fontWeight="500" fontSize="1.4rem" color="#444444">
                    Ethical
                  </Box>
                  <Box mt={2} width="88%" className="valueAlign">
                    <Typography>
                      We conduct business with integrity and in accordance
                      with the highest ethical standards. We make a conscious
                      effort to choose to do the right thing, at all times.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="valueItem">
              <Grid container direction="row" justifyContent="center" spacing={6}>
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/icons/authentic.svg"
                    width="200"
                    height="200"
                    alt="placeholder"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box fontWeight="500" fontSize="1.4rem" color="#444444">
                    Authentic
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      We strive to be genuine and maintain authenticity in all
                      that we do.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="valueItem">
              <Grid container direction="row" justifyContent="center" spacing={6}>
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/icons/proactive.svg"
                    width="200"
                    height="200"
                    alt="placeholder"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box fontWeight="500" fontSize="1.4rem" color="#444444">
                    Proactive
                  </Box>
                  <Box mt={2} width="88%" className="valueAlign">
                    <Typography>
                      We are hungry for solutions and will never give up,
                      always searching for ways to improve the specialty
                      pharmacy enrollment process. We believe in empowerment,
                      taking action and collaboration.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="valueItem">
              <Grid container direction="row" justifyContent="center" spacing={6}>
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/icons/learner.svg"
                    width="200"
                    height="200"
                    alt="placeholder"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box fontWeight="500" fontSize="1.4rem" color="#444444">
                    Curious
                  </Box>
                  <Box mt={2} width="88%" className="valueAlign">
                    <Typography>
                      We intentionally listen to our customers, specialty drug
                      patients and one another to understand problems and
                      perspectives. We are ever-learning and ever-evolving.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Pillars of our success section  */}
      <Box
        mt={8}
        mx="auto"
        textAlign="center"
        bgcolor="#E9EEF1"
        width="100%"
        py="150px"
      >
        <Box
          fontWeight="600"
          fontSize="2.5rem"
          color="#444444"
          textAlign="center"
        >
          The pillars of our success
        </Box>

        <Box mt={8} mx="auto">
          <Grid container justifyContent="center" direction="row">
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <Avatar
                    alt="RxLightning Bolt"
                    src="https://cdn.rxlightning.com/images/website/icons/patientprovider.svg"
                    variant="square"
                    style={{
                      height: '120px',
                      width: '120px',
                    }}
                  />
                </Grid>
                <Grid item lg={11} md={8}>
                  <Typography>Patient and provider-centric</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <Avatar
                    alt="RxLightning Bolt"
                    src="https://cdn.rxlightning.com/images/website/icons/collaborative.svg"
                    variant="square"
                    style={{
                      height: '120px',
                      width: '120px',
                    }}
                  />
                </Grid>
                <Grid item lg={10} md={8}>
                  <Typography>Collaborative and innovative</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <Avatar
                    alt="RxLightning Bolt"
                    src="https://cdn.rxlightning.com/images/website/icons/honesty.svg"
                    variant="square"
                    style={{
                      height: '120px',
                      width: '120px',
                    }}
                  />
                </Grid>
                <Grid item lg={8} md={8}>
                  <Typography>Honesty and integrity</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <Avatar
                    alt="RxLightning Bolt"
                    src="https://cdn.rxlightning.com/images/website/icons/focus.svg"
                    variant="square"
                    style={{
                      height: '120px',
                      width: '120px',
                    }}
                  />
                </Grid>
                <Grid item lg={8} md={8}>
                  <Typography>Focus and discipline</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Breadth of our partnerships  */}
      {/* <Box
      width="100%"
      bgcolor="#F5A3C0"
      textAlign="center"
      mt={8}
      py={9}
      mx="auto"
    >
      <Box
        fontWeight="600"
        textAlign="center"
        fontSize="2.5rem"
        color="#003764"
      >
        The breadth of our partnerships
      </Box>

      <Box mt={8}>
        <Grid container justify="center" spacing={2}>
          <Grid item md={3}>
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={2}
              direction="column"
            >
              <Grid item>
                <img
                  src={hipaalogo}
                  width="200"
                  height="200"
                  alt="placeholder"
                />
              </Grid>
              <Grid item md={6}>
                <Box
                  fontWeight="900"
                  fontSize="3rem"
                  color="#fff"
                  fontFamily="Shadows Into Light"
                >
                  240K+
                </Box>
                <Box mt={1} fontSize="1.5rem" fontWeight="400" color="#fff">
                  National Provider Identifiers (NPIs)
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={2}
              direction="column"
            >
              <Grid item>
                <img
                  src={usicon}
                  width="200"
                  height="200"
                  alt="placeholder"
                />
              </Grid>
              <Grid item md={6}>
                <Box
                  fontWeight="900"
                  fontSize="3rem"
                  color="#fff"
                  fontFamily="Shadows Into Light"
                >
                  500+
                </Box>
                <Box mt={1} fontSize="1.5rem" fontWeight="400" color="#fff">
                  Locations across the country
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box> */}

      <Box mt={8} mx="auto" textAlign="center" py="100px" bgcolor="#E9EEF1">
        <Box
          fontWeight="600"
          fontSize="2.5rem"
          color="#444444"
          textAlign="center"
        >
          Meet The Founders
        </Box>
        <Box mt="100px">
          <Grid container direction="column" spacing={6}>
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={6}
              >
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/team/juliaregan.jpeg"
                    width="300"
                    alt="placeholder"
                    style={{ borderRadius: '50%' }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Grid container justifyContent="flex-start">
                    <Grid item>
                      <Box
                        fontWeight="700"
                        fontSize="1.4rem"
                        color="#444444"
                        className="founderHeading"
                      >
                        Julia Regan, CEO & Co-Founder
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mt={2}>
                        <Typography
                          style={{ textAlign: 'left' }}
                          className="founderStyle"
                        >
                          Julia Regan has over 15 years of experience in the
                          health technology industry. She has in depth
                          experience designing and launching innovative
                          solutions to help remove friction for healthcare
                          providers, patients, payers, and pharmaceutical
                          organizations. <br />
                          <br />
                          Julia’s most notable product accomplishment is in
                          2015, she designed and launched the first real-time
                          benefit check and price transparency solution at the
                          point of prescribing. For the last 5 years, she has
                          been committed to working with the industry pharmacy
                          benefit managers, manufacturers, and EHRs to ensure
                          the functionality is broadly available. <br />
                          <br />
                          Julia earned a bachelor's degree in Psychology from
                          Temple University and a MBA in healthcare management
                          from Florida Institute of Technology. She currently
                          resides in Greenville, Indiana with her husband,
                          Michael, and two children, Berkley and Cooper.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row-reverse"
                alignItems="center"
                justifyContent="center"
                spacing={6}
              >
                <Grid item>
                  <img
                    src="https://cdn.rxlightning.com/images/website/team/bradallen.jpg"
                    width="300"
                    alt="placeholder"
                    style={{ borderRadius: '50%' }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Grid container justifyContent="flex-start">
                    <Grid item>
                      <Box
                        fontWeight="700"
                        fontSize="1.4rem"
                        color="#444444"
                        className="founderStyle"
                      >
                        Brad Allen, CTO & Co-Founder
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mt={2}>
                        <Typography
                          style={{ textAlign: 'left' }}
                          className="founderStyle"
                        >
                          Brad Allen has over 12 years of success creating
                          software in the startup industry. After obtaining
                          his bachelor’s degree from Iowa State University in
                          2008, he worked as a Software Engineer across
                          multiple industries, including Finance, Agriculture,
                          and Health Care. <br />
                          <br />
                          Brad has designed and implemented highly scalable
                          solutions that have been used to facilitate banking
                          ACH transactions, agronomic data processing and
                          health care price transparency. Prior to
                          RxLightning, Brad was an initial engineer at a
                          Health Care company where he grew the engineering
                          team, architected the cloud based solution while
                          keeping up with SOC 2 and HIPAA compliance. <br />
                          <br />
                          After working with Julia at a previous startup to
                          help create an industry leading price transparency
                          solution he is excited for how RxLightning will
                          evolve the Specialty Enrollment space!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Industry recognition  */}
      <Box mt={8} mx="auto" textAlign="center" py="100px">
        <Box
          fontWeight="600"
          fontSize="2.5rem"
          color="#444444"
          textAlign="center"
        >
          Industry Recognition
        </Box>
        <Box mt={12}>
          <Grid container alignItems="flex-end" justifyContent="center" spacing={2}>
            <Grid item md={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column">
                <Grid item>
                  <img src="https://cdn.rxlightning.com/images/website/TP-Mira-Winner-2022_Black-Gold.png" width="100%" alt="placeholder" />
                </Grid>
                <Grid item md={8}>
                  <Box fontWeight="fontWeightMedium" fontSize="1.4rem">
                    2022 Mira Awards
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <hr width="1" size="250" color="#2F89CC" />
              </Grid>
            </Hidden>
            <Grid item md={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <img src="https://cdn.rxlightning.com/images/website/logos/vogt-logo.png" width="80%" alt="placeholder" />
                </Grid>
                <Grid item md={8}>
                  <Box fontWeight="fontWeightMedium" fontSize="1.4rem">
                    2020 Vogt Award Winner
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <hr width="1" size="250" color="#2F89CC" />
              </Grid>
            </Hidden>
            <Grid item md={2} className="awardMargin">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <img src="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png" width="100%" alt="placeholder" />
                </Grid>
                <Grid item md={10}>
                  <Box fontWeight="fontWeightMedium" fontSize="1.4rem">
                    Fastest Growing Company in Louisville
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <hr width="1" size="250" color="#2F89CC" />
              </Grid>
            </Hidden>
            <Grid item md={2} className="awardMargin">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid item>
                  <img src="https://cdn.rxlightning.com/images/website/logos/xlerate.png" width="100%" alt="placeholder" />
                </Grid>
                <Grid item md={10} xs={10}>
                  <Box fontWeight="fontWeightMedium" fontSize="1.4rem">
                    2020 Accelerator Cohort Participant
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt="100px">
            <Link to="/news" style={{ textDecoration: 'none' }}>
              <Button
                color="primary"
                variant="outlined"
                style={{
                  height: '60px',
                  fontSize: '1.25rem',
                  marginTop: '10px',
                  width: '210px',
                  letterSpacing: '0.1rem',
                }}
              >
                LATEST NEWS
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>

      {/* Our Rockstar Team section  */}
      {/* <Box width="100%" bgcolor="#B4D7E3" textAlign="center" mt={10} py={8}>
      <Box
        fontWeight="600"
        textAlign="center"
        fontSize="2.5rem"
        color="#003764"
      >
        Our Rockstar Team
      </Box>

      <Box mt={4} width="60%" margin="auto">
        <Grid container justify="space-evenly" alignItems="flex-start">
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Julia Regan"
              image={juliaregan}
              title="CEO &#38; CO-FOUNDER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/julia-crouse-regan-mba-150aa712/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Brad Allen"
              image={bradallen}
              title="CTO &#38; CO-FOUNDER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/bradallen0/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Tom Slaughter"
              image={tomslaughter}
              title="OUTSIDE COUNSEL"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/thomaslslaughter/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Gary Branning"
              image={garybranning}
              title="ADVISOR"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/thomaslslaughter/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Ann Martin"
              image={annmartin}
              title="CFO"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/ann-k-martin/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Ashley Spurgeon"
              image={ashleyspurgeon}
              title="EXECUTIVE ASSISTANT"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/ashley-spurgeon-84483a201/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Caitlin Wood"
              image={caitlinwood}
              title="PRINCIPAL ENGINEER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/caitlin-wood-866bb266/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Tyler Higgs"
              image={tylerhiggs}
              title="SENIOR DATA ANALYST"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/tyler-higgs-1aa01380/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="James Maddox"
              image={jamesmaddox}
              title="JUNIOR DEVELOPER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/james-maddox/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Tyron Monts"
              image={tyronmonts}
              title="ANALYST"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/tyron-monts-2905/"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TeamMember
              name="Deb Gring"
              image={debgring}
              title="DIRECTOR OF OPERATIONS"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/deb-g-5b2569201/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Jordan Churchill"
              image={jordanchurchill}
              title="USER ACQUISITION"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/jordan-churchill-a5564568/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Laura Williams"
              image={laurawilliams}
              title="SENIOR ENGINEER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/laura-k-williams/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Joshua Abell"
              image={joshuaabell}
              title="ANALYST"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/joshua-abell-1b33a927/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Aaron Arnold"
              image={aaronarnold}
              title="ANALYST"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/aaron-arnold-18bb6b207/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Elizabeth Johnson"
              image={elizabethjohnson}
              title="DIRECTOR OF PRODUCT"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/elizabeth-johnson-lpn-cpc/"
            />
          </Grid> */}
      {/* <Grid item md={3}>
            <TeamMember
              name="Kriste Goad, Fuoco"
              image={kristegoad}
              title="CHIEF FIRE STARTER"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/kristegoad/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Madeline Abrams, Fuoco"
              image={madelineabrams}
              title="ACCOUNT EXECUTIVE"
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/madeline-margaret-abrams/"
            />
          </Grid>
          <Grid item md={3}>
            <TeamMember
              name="Katie Clark, Fuoco"
              image={katieclark}
              hasLinkedIn={true}
              linkedinlink="https://www.linkedin.com/in/katherineeveclark/"
            />
          </Grid> */}
      {/* </Grid> */}
      {/* </Box> */}
      {/* </Box> */}

      {/* CTA  */}
      <CTAButton />

      <InfoFooter />
      <Footer />
    </Box>
  </>;
}

export default About;
