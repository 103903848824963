import React from 'react';
import { Box, Typography, Grid, Hidden, Button } from '@mui/material';
import ColoredLine from '../components/Line';
import HeroInfo from '../components/HeroInfo';
import Card from '../components/Card';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';
import '../img/icons/fontawesome-pro/css/all.css';
import './css/home.css';
import { Timeline, Tween } from 'react-gsap';

function Home() {
  return <>
    {/* Top section  */}
    <Hidden mdDown>
      <div className="hero">
        <div className="hero-text">
          <Timeline
            target={
              <div className="hero-text-title">
                Specialty drug enrollment to love
              </div>
            }
          >
            <Tween to={{ opacity: 1 }} duration={0.3} />
            <Tween from={{ yPercent: 50 }} duration={0.5} />
          </Timeline>
          <Timeline
            target={
              <div className="hero-text-subtitle">
                Digitized forms for 900+ specialty medications to get your
                patients started on the therapies they need with RxLightning
                speed.
              </div>
            }
          >
            <Tween to={{ opacity: 1 }} duration={0.5} />
            <Tween from={{ yPercent: 150 }} duration={1} />
          </Timeline>
          <Timeline
            target={
              <a
                href={process.env.REACT_APP_REGISTRATION_URL}
                style={{ textDecoration: 'none' }}
              >
                <button>GET STARTED</button>
              </a>
            }
          >
            <Tween from={{ opacity: 0 }} delay={2} />
            <Tween to={{ opacity: 1 }} duration={0.6} />
          </Timeline>
          <Timeline
            target={
              <div className="hero-text-bottom">
                No risk. No fee. Free to providers and their staff.
              </div>
            }
          >
            <Tween from={{ opacity: 0 }} delay={2} />
            <Tween to={{ opacity: 1 }} duration={0.6} />
          </Timeline>
        </div>

        <Timeline
          target={
            <svg
              class="heart-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 495.5 661.29"
            >
              <Timeline
                target={
                  <path
                    class="heart-line"
                    stroke-width="14"
                    stroke="#003764"
                    stroke-dasharray="1538"
                    stroke-dashoffset="1538"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="none"
                    d="M67.13,252C-93.09,83.72,101.68-85.63,246.16,65.93,379.53-83.56,583.62,80.14,438.61,239.47L257.37,415.23l80.19,24.86L159.19,654.29"
                  />
                }
              >
                <Tween from={{ scale: 1.2 }} />
                <Tween
                  to={{
                    ease: 'power1.in',
                    attr: { 'stroke-dashoffset': 0 },
                  }}
                  duration={1.5}
                />
                <Tween to={{ scale: 1 }} duration={0.2} />
              </Timeline>
            </svg>
          }
        >
          <Tween
            to={{
              scale: 1.2,
              transformOrigin: 'center center',
            }}
            duration={0.15}
          />
          <Tween to={{ scale: 1 }} duration={0.3} />
        </Timeline>
      </div>
    </Hidden>
    <Hidden mdUp>
      <Box mt="100px" mx="auto">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item align="center" lg={6} md={6} sm={10} xs={11}>
            <Typography variant="h1" color="primary" className="homeHeading">
              Specialty drug enrollment to love
            </Typography>
            <Typography variant="body1" textAlign="center">
              <p
                style={{
                  fontWeight: 600,
                  lineHeight: '35px',
                  color: '#444444',
                }}
                className="homeText"
              >
                Digitized forms for 900+ specialty medications to get your
                patients started on the therapies they need with RxLightning
                speed.
              </p>
            </Typography>
            <a
              href={process.env.REACT_APP_LOGIN_URL}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '50px',
                  fontSize: '1.2rem',
                  marginTop: '10px',
                  width: '200px',
                  fontWeight: '400',
                  letterSpacing: '0.1rem',
                }}
              >
                Get Started
              </Button>
            </a>
            <p
              style={{
                marginTop: '30px',
                fontSize: '0.9rem',
                fontWeight: 400,
                color: 'gray',
              }}
            >
              No risk. No fee. Free to providers and their staff.
            </p>
          </Grid>
          <Hidden mdDown>
            <Grid item align="center" lg={2} md={3} sm={12} xs={12}>
              <img
                src="https://cdn.rxlightning.com/images/heart-bolt.png"
                width="300"
                height="375"
                alt="RxLightning Heart Bolt Logo"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Hidden>
    <Box width="100%">
      {/* Second section  */}
      <Box
        width="100%"
        mx="auto"
        paddingTop="100px"
        className="specialtySection"
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item align="left" md={5}>
            <Box
              fontWeight="600"
              fontSize="2.2rem"
              color="#444444"
              className="specialtySectionTitle"
            >
              Specialty enrollment is complex.
            </Box>
            <Box
              fontWeight="600"
              fontSize="2.2rem"
              color="#444444"
              className="specialtySectionTitle"
            >
              We make it simple.
            </Box>
            <Typography
              variant="body1"
              textAlign="center"
              style={{ color: '#444444' }}
            >
              <p className="specialtySectionText">
                With our single solution, providers can quickly and easily
                submit enrollments for patients on any specialty medication to
                any specialty pharmacy.
              </p>
            </Typography>
            <Grid container direction="column">
              <Box fontWeight="fontWeightMedium" fontSize="1rem">
                <Grid container alignItems="center">
                  <Grid item md={2} xs={3}>
                    <i
                      className="fas fa-sort-amount-down"
                      style={{
                        color: '#2F89CC',
                        fontSize: '3.8rem',
                        marginRight: '25px',
                      }}
                    ></i>
                  </Grid>
                  <Grid item md={10} xs={9}>
                    <Typography
                      style={{ color: '#444444', fontWeight: '600' }}
                    >
                      Reduce administrative burden
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box fontWeight="fontWeightMedium" fontSize="1rem" mt={3}>
                <Grid container alignItems="center">
                  <Grid item md={2} xs={3}>
                    <i
                      className="fas fa-tty"
                      style={{
                        color: '#2F89CC',
                        fontSize: '3.8rem',
                        marginRight: '25px',
                      }}
                    ></i>
                  </Grid>
                  <Grid item md={10} xs={9}>
                    <Typography
                      style={{ color: '#444444', fontWeight: '600' }}
                    >
                      Streamline communications
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box fontWeight="fontWeightMedium" fontSize="1rem" mt={3}>
                <Grid container alignItems="center">
                  <Grid item md={2} xs={3}>
                    <i
                      className="fas fa-shipping-fast"
                      style={{
                        color: '#2F89CC',
                        fontSize: '3rem',
                        marginRight: '25px',
                      }}
                    ></i>
                  </Grid>

                  <Grid item md={10} xs={9}>
                    <Typography
                      style={{ color: '#444444', fontWeight: '600' }}
                    >
                      Accelerate speed to therapy
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item md={4} sm={4} xs={12} align="center">
              <img
                src="https://cdn.rxlightning.com/images/website/laptop1_opt.jpg"
                width="100%"
                alt="Computer with display of RxLightning portal"
                style={{ maxWidth: '750px' }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      {/* Third section  */}
      <Box my="100px" py={8} className="supportSection">
        <Box mb={4}>
          <ColoredLine color="#0088CC" />
        </Box>
        <Grid container justifyContent="center">
          <Grid item align="center" md={6} sm={6} xs={8}>
            <Box
              color="#0088CC"
              fontSize="2.8rem"
              fontWeight="600"
              className="supportSectionTitle"
            >
              <Typography
                style={{
                  color: '#0088CC',
                  fontSize: '2.8rem',
                  fontWeight: '600',
                }}
                className="supportSectionTitle"
              >
                We support all specialty pharmacies and specialty medications.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4}>
          <ColoredLine color="#0088CC" />
        </Box>
      </Box>

      {/* Fourth section  */}
      <Box className="mainCards">
        <HeroInfo
          subtitle="FREE"
          title1="Consider us an advocate for the advocate"
          titleColor="#444444"
          width="90%"
          desc="Register users, link to prescribers and create robust patient profiles - all for free. Complimentary, customizable QuickStart guides to get your office up and running with ease."
          descWidth="89%"
          link="Create your free account"
          isList={true}
          url={process.env.REACT_APP_REGISTRATION_URL}
          item1="Verified prescriber and patient profiles&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          item2="Comprehensive specialty drug and pharmacy selection"
          item3="Digital, consolidated pharmacy and hub enrollment forms"
          item4="REMs and Patient Assistance Program Enrollment"
          image="https://cdn.rxlightning.com/images/website/threedoctors_opt.jpg"
          fourChecks={true}
          isLink={true}
          altdesc="Three physicians, two men and one woman, looking and smiling at chart outside of hospital"
        />

        <HeroInfo
          subtitle="FAST"
          title1="Complete a patient enrollment in less than 10 minutes"
          titleColor="#444444"
          width="100%"
          desc="Stop searching for the right forms and start getting your patients on their prescribed therapy faster."
          descWidth="90%"
          link="Say no to paperwork"
          isList={false}
          url={process.env.REACT_APP_REGISTRATION_URL}
          direction="row-reverse"
          testimonial="RxLightning saves our practice time and money, streamlining the enrollment process for our patients."
          name="Marc Del Bono"
          company="CMA - "
          title="Biologics Coordinator"
          image="https://cdn.rxlightning.com/images/website/laughingbywall_opt.jpg"
          isLink={true}
          altdesc="Two women laughing by wall with one woman's head on the other woman's shoulder"
        />
        <HeroInfo
          subtitle="SIMPLE"
          title1="Always the right form, customized to your area of practice"
          titleColor="#444444"
          width="80%"
          desc="Our platform is built using smart logic to guide you through a standard list of questions, eliminating both doubt and annoying duplication of questions."
          descWidth="92%"
          link="Get started now"
          isList={false}
          url={process.env.REACT_APP_REGISTRATION_URL}
          testimonial="Much of the manual labor with paperwork will be eliminated. Once you enroll a patient they will still be in the system and you will not have to enter their information again."
          name="Heather B."
          company="MA"
          title=" Dermatologist"
          image="https://cdn.rxlightning.com/images/website/happydoctors.jpg"
          isLink={true}
          altdesc="physicians joyful"
        />
      </Box>
      <Card />
      <InfoFooter />
      <Footer />
    </Box>
  </>;
}

export default Home;
