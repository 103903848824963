import {Box, Grid, Hidden, Typography} from '@mui/material';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactForm from '../components/ContactForm';
import CTAButton from '../components/CTAButton';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';

const styles = {
  paperContainer: {
    backgroundImage: `url(https://cdn.rxlightning.com/images/website/banner/contactphoto_opt.jpg)`,
    height: '500px',
    width: '100%',
    backgroundPosition: '10% 25%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff',
    textAlign: 'center',
    display: 'grid',
  },
};

function Contact() {
  return <>
    <Box style={{ overflowX: 'hidden' }}>
      {/* Top section  */}
      <Box mt={4} style={styles.paperContainer} width="100%">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xl={2} md={8}>
            <Typography variant="h1">
              <Box fontWeight="600" color="#fff" lineHeight="75px">
                Contact
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={8}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          direction="row"
          spacing={2}
        >
          <Hidden mdDown>
            <Grid item md={3} xs={8}>
              <Box fontSize="2.5rem" fontWeight="600" color="#444444">
                Get in Touch
              </Box>
              <Box mt={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Box fontSize="1.4rem" fontWeight="500">
                          Phone
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box fontSize="1.2rem" mt="15px">
                          1-855-485-0579
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Box fontSize="1.4rem" fontWeight="500" mt="15px">
                          Address
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box fontSize="1.2rem" mt="15px">
                          New Albany, IN
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Box fontSize="1.4rem" fontWeight="500" mt="15px">
                          Connect with Us
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row">
                          <Grid item hidden>
                            <a
                              href="https://www.facebook.com/RxLightningInc"
                              style={{ textDecoration: 'none' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FacebookIcon
                                color="primary"
                                style={{ width: '50px', height: '50px' }}
                              />
                            </a>
                          </Grid>
                          <Grid item hidden>
                            <a
                              href="https://twitter.com/rxlightning?lang=en"
                              style={{ textDecoration: 'none' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TwitterIcon
                                color="primary"
                                style={{ width: '50px', height: '50px' }}
                              />
                            </a>
                          </Grid>
                          <Grid item>
                            <a
                              href="https://www.linkedin.com/company/rxlightning/"
                              style={{ textDecoration: 'none' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <LinkedInIcon
                                color="primary"
                                style={{ width: '50px', height: '50px' }}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Hidden>
          <Grid item md={4} xs={10}>
            <Box
              fontSize="2.5rem"
              fontWeight={600}
              color="#444444"
              textAlign="center"
            >
              Send a Message
            </Box>
            <ContactForm />
          </Grid>
        </Grid>
      </Box>
      <CTAButton />
      <InfoFooter />
      <Footer />
    </Box>
  </>;
}

export default Contact;
