import { Box, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';
import CTAButton from '../components/CTAButton';
import vogt from '../img/vogt-logo.png';
import microsoft from '../img/microsoft-startup.jpg';
import mira from '../img/TP-Mira-Winner-2022_Black-Gold.png'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Link } from 'react-router-dom';

function Card() {
  return <>
    {/* Card 1  */}
    <Box bgcolor="#E9EEF1" textAlign="center" mt="100px" py="120px">
      <Box
        width={2 / 3}
        mx="auto"
        fontWeight="600"
        fontSize="2.5rem"
        color="#444444"
      >
        Patient enrollment is an increasingly archaic process
      </Box>

      <Box width={3 / 4} mx="auto" mt={6}>
        <Grid container direction="column">
          <Grid container justifyContent="space-evenly">
            <Grid item md={2} sm={4} xs={12}>
              <Box fontWeight="600" fontSize="3rem" color="#444444">
                900+
              </Box>
              <Box mt={1} fontSize="1.2rem" fontWeight="400" color="#444444">
                Specialty drugs on the market today, each with varying forms
              </Box>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <Box fontWeight="600" fontSize="3rem" color="#444444">
                90%
              </Box>
              <Box mt={1} fontSize="1.2rem" fontWeight="400" color="#444444">
                Of provider communication is still done by phone or fax
              </Box>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <Box fontWeight="600" fontSize="3rem" color="#444444">
                3-15
              </Box>
              <Box mt={1} fontSize="1.2rem" fontWeight="400" color="#444444">
                Hours spent per week on paperwork for a single patient
              </Box>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Box fontWeight="600" fontSize="3rem" color="#444444">
                5-30
              </Box>
              <Box mt={1} fontSize="1.2rem" fontWeight="400" color="#444444">
                Days patients spend waiting for treatment
              </Box>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Box fontWeight="600" fontSize="3rem" color="#444444">
                52%
              </Box>
              <Box mt={1} fontSize="1.2rem" fontWeight="400" color="#444444">
                Of the time it takes more than 1 week for a patient to start on therapy
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        width={3 / 4}
        fontSize="1.25rem"
        fontWeight="fontWeightMedium"
        pt={7}
        mt={2}
        mx="auto"
      >
        <Box fontSize="1.95rem" fontWeight="700" color="#0088CC">
          We reduce the administrative burden of healthcare providers and
          pharmacies.
        </Box>
      </Box>
    </Box>

    {/* Single testimonial  */}
    <Hidden mdDown>
      <Box textAlign="center" py="150px" bgcolor="#E9EEF1" mt={5}>
        <Box mx="auto" fontWeight="500" fontSize="2.5rem" color="#444444">
          Customers love us - and you will, too!
        </Box>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="space-around"
              spacing={9}
            >
              <Grid item>
                <FormatQuoteIcon
                  style={{
                    width: '200px',
                    height: '200px',
                    transform: 'rotate(180deg)',
                    color: '#0088CC',
                  }}
                />
              </Grid>
              <Grid item></Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container>
              <Grid item>
                <Box fontSize="1.4rem" color="#444444" width="93%" mx="auto">
                  After using the system, I found it to be extremely user
                  friendly and easy to navigate. I truly like having all of
                  our SPP patients in one location. As soon as I know a
                  patient is going to need to utilize SPP, I enter the
                  patient’s information into RxLightning, complete the
                  enrollment and submit. I seriously waited for something to
                  happen, but that’s it! <br />
                  <br />
                  <b>Lacey V, CMA - Biologics Coordinator</b>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={8}
            >
              <Grid item></Grid>
              <Grid item></Grid>
              <Grid item md={3}>
                <FormatQuoteIcon
                  style={{
                    width: '200px',
                    height: '200px',
                    color: '#0088CC',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Hidden>

    {/* Card 2  */}

    <Box mx="auto" textAlign="center" py="150px" mt={5}>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Box
            fontWeight="600"
            fontSize="2.5rem"
            color="#444444"
            width="85%"
            mx="auto"
          >
            <Typography
              className="awardHeading"
              style={{
                fontSize: '2.5rem',
                fontWeight: 600,
                color: '#444444',
                justify: 'center',
              }}
            >
              Our award-winning platform simplifies, streamlines and automates
              the complex manual specialty enrollment process.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box width={3 / 4} mx="auto" mt={6} mb={4}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid item md={4} xs={12}>
            <Box>
              <img src={mira} width={250} alt="mira startup award logo" />
            </Box>
            <Box mt={2} fontSize="1.2rem">
                2022 Mira Awards
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box>
              <img src={vogt} width="90" alt="vogt-logo" />
            </Box>
            <Box mt={1} fontSize="1.2rem">
              2020 Vogt Award Winner
            </Box>
          </Grid>
          <Grid item md={4} xs={12} className="awardMargin">
            <Box mt={2} fontWeight="fontWeightMedium" fontSize="1.5rem">
              <Link to="/news">

              <img src={microsoft} width="215" alt="microsoft startup logo" />
              </Link>
            </Box>
            <Box mt={3} fontSize="1.2rem">
              Microsoft for Startups Member
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>

    {/* Card 3  */}
    <CTAButton />
  </>;
}

export default Card;
