import React from 'react'
import {Box, Grid, Typography} from '@mui/material';
import Footer from '../components/Footer';

function Terms() {
    return <>
    <Box py="125px">
        <Grid container justifyContent="center" direction="column" alignItems="center">
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <Box
            fontSize="1.5rem"
            fontWeight={400}
            color="#444444"
            textAlign="center"
            mb="40px"
            >
            TERMS OF USE
            </Box>

            <Typography>
                Welcome to RxLightning.com. RxLightning Inc. ( “RxLightning”) provides website features
                and other products and services to you when you visit rxlightning.com, contact RxLightning,
                or otherwise interact with RxLightning, use RxLightning products or services, or use any
                software provided by RxLightning in connection with any of the foregoing (collectively,
                “RxLightning Services”). RxLightning provides the RxLightning Services subject to these
                Terms of Use. These Terms of Use constitute a legally binding agreement between you and
                RxLightning.
                <br />
                <br />
                In order to use the RxLightning Services, you must be a licensed healthcare provider with the
                intent to prescribe a prescription or an employee of a licensed healthcare provider. If you are
                an employee of such an organization, you represent that you are authorized to agree to these
                Terms of Use.
                <br />
                <br />
                <strong>By registering for RxLightning Services, you agree to these Terms of Use. Please read
                them carefully. If you do not agree, do not use the RxLightning Services.</strong>
                <br />
                <br />
                RxLightning may amend any part of these Terms of Use at our sole discretion by posting the
                revised Terms of Use on the RxLightning website. You should periodically check these Terms
                of Use for changes. Your continued use of the RxLightning Services following the posting of
                any changes to these Terms of Use constitutes your acceptance of the terms.
                <br />
                <br />
                <strong>Service</strong>
                <br />
                <br />
                RxLightning Services, including, but not limited to, specialty medication enrollment, patient
                financial assistance programs, risk evaluation mitigation programs, prior authorization,
                enrollment assistance, including follow up communications, prescription management and
                follow up communications to patients regarding patient enrollments and services.
                <br />
                <br />
                Submission of an application does not guarantee funding through a patient assistance program.
                Funding is determined by the sponsoring organization or manufacturer and the eligibility criteria,
                not RxLightning.
                <br />
                <br />
                RxLightning may establish business relationships with pharmaceutical manufacturers, payors,
                specialty pharmacies to facilitate the RxLightning Services and may share protected health
                information pursuant to a valid HIPAA Authorization that complies with 45 CFR § 164 to
                support certain program drugs prescribed to the patient.
                <br />
                <br />
                <strong>Privacy</strong>
                <br />
                <br />
                RxLightning provides the RxLightning Services as a business associate to healthcare providers.
                To use the RxLightning Services pursuant to these Terms of Use, healthcare providers,
                including employees agreeing to these Terms of Use on behalf of an employer that is a
                healthcare provider, must read and agree to the Business Associate Agreement. The Business
                Associate Agreement requires RxLightning to protect PHI received from a covered entity and
                specifies the purposes for which it may be lawfully used and disclosed by RxLightning. Use of
                the RxLightning Services constitutes acceptance of the terms of the Business Associate
                Agreement.
                <br />
                <br />
                To provide the RxLightning Services at no cost to healthcare providers, RxLightning may
                share de-identified data with third parties including for various purposes such as, but not
                limited to, billing and validation of certain transactions, evaluating the effectiveness of the
                RxLightning Services, and providing data analytics and market insights, including prescribing
                practices. Additionally, data is used for continuous improvement of current services and to
                streamline and automate the specialty enrollment process. You acknowledge and agree that
                RxLightning, or its subcontractors, or both, are hereby authorized to de-identify PHI in
                accordance with 45 C.F.R. § 164.514(a)-(c) of HIPAA, and subsequently use and disclose such
                de-identified data as permitted by applicable law.
                <br />
                <br />
                Unless prohibited by applicable law, you authorize RxLightning to use and disclose your
                information provided in connection with the RxLightning Services, including, your National
                Provider Identifier number (“NPI number”).
                <br />
                <br />
                You acknowledge and agree that RxLightning or its affiliate may also engage directly with
                patients.
                <br />
                <br />
                <strong>Content</strong>
                <br />
                <br />
                All information, data, software, photographs, graphics, videos, text, images, typefaces, sounds,
                logos, and other material, including but not limited to the selection, coordination, arrangement,
                and enhancement of such content, contained in any of the RxLightning Services is owned,
                controlled, or licensed by or to RxLightning, and is protected by trade dress, copyright, patent,
                trademark, and other intellectual property rights and laws. You may not use the RxLightning
                name, any related logos or trademarks, or any of the content described above without the
                express written consent of RxLightning.
                <br />
                <br />
                <strong>License and Access</strong>
                <br />
                <br />
                Subject to your compliance with these Terms of Use, RxLightning or its content providers
                grant you limited, non-exclusive, non-transferable, non-sublicensable access to use the
                RxLightning Services solely in connection with the care of a patient. You are not permitted to
                resell or use any RxLightning Services for commercial purposes. The permission granted does
                not include any rights in or to the contents of the RxLightning Services (including the content
                described above); any collection and use of any product listings, descriptions, or prices; any
                derivative use of any of the RxLightning Services or its contents; any downloading, copying,
                or other use of account information for the benefit of any third party; or any use of data
                mining, robots, or similar data gathering and extraction tools. All rights not expressly granted
                to you in these Terms of Use are reserved and retained by RxLightning or its licensors,
                suppliers, publishers, rightsholders, or other content providers. No RxLightning Service, nor
                any part of any RxLightning Service, may be reproduced, duplicated, copied, sold, resold,
                visited, or otherwise exploited for any commercial purpose without express written consent of
                RxLightning. You may not frame or utilize framing techniques to enclose any trademark, logo,
                or other proprietary information (including images, text, page layout, or form) of RxLightning
                without RxLightning’s express written consent.
                <br />
                <br />
                You must be at least 18 years of age to use the RxLightning Services and a licensed healthcare
                provider or employed by a licensed healthcare provider. You may not misuse the RxLightning
                Services. You may not post or transmit through any of the RxLightning Services any content
                that is illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual
                property rights (including publicity rights), otherwise injurious to third parties or
                objectionable, contains or consist of software viruses or any form of "spam" or unsolicited
                commercial electronic messages. You may use the RxLightning Services only as permitted by
                law. The licenses granted by RxLightning terminate if you do not comply with these Terms of
                Use.
                <br />
                <br />
                <strong>Your Account</strong>
                <br />
                <br />
                You will be required to create your own RxLightning account to access the RxLightning
                Services and will be required to be logged in to the account. For your account, you agree to
                provide and maintain true, current, and complete information about yourself. You are
                responsible for maintaining the confidentiality of your account and password and for
                restricting access to your account, and you agree to accept responsibility for all activities that
                occur under your account or password. You agree to notify RxLightning immediately of any
                unauthorized use of your account or any other breach of security related to your account.
                RxLightning will not be liable for any loss that you may incur as a result of someone else using
                your password or account with or without your knowledge. You may be held liable for losses
                incurred by RxLightning or another party as a result of someone else using your account or
                password. You may not use any other account or allow anyone the use of your account.
                RxLightning reserves the right to refuse service, suspend or terminate your account, terminate
                your rights to use RxLightning Services, and remove or edit content in its sole discretion.
                <br />
                <br />
                Enrollment forms, patient assistance forms, prior authorization forms, and other forms and
                services collect information needed for specialty pharmacies to dispense medication, complete
                benefit verification and or prior authorization. A user must submit PHI in order to use the
                RxLightning Services available on this website. Other healthcare providers will receive and
                use the PHI submitted to facilitate the process to dispense the medication to a patient.
                <br />
                <br />
                You agree we may substitute a fax number provided by you and submitted with a enrollment
                request for submission by us to the specialty pharmacy, manufacturer, payor, PBMs or other
                payors on your behalf with a RxLightning fax number so that enrollment communication will
                be faxed to us and delivered as part of the RxLightning Services. This substitution helps
                enhance the RxLightning Services provided to you, your patient and other authorized
                providers.
                <br />
                <br />
                <strong>Communications with RxLightning</strong>
                <br />
                <br />
                When you use RxLightning Services, or send emails, text messages, and other communications
                from your desktop or mobile device to us, you may be communicating with us electronically.
                You consent to receive communications (including emails, texts, faxes, or notices and
                messages on this Website or through other RxLightning Services) by or on behalf of
                RxLightning to any email address, phone number, or fax number associated with your account
                or otherwise directly or indirectly provided to RxLightning. You can retain copies of these
                communications for your records. You agree that all agreements, notices, disclosures, and
                other communications that we provide to you electronically satisfy any legal requirement that
                such communications be in writing. These communications may include personal information
                about prescriptions, treatment, or benefits. If you choose to share access to your mobile phone,
                carrier account, email, or RxLightning account with others those individuals might also be able
                to see this information.
                <br />
                <br />
                <strong>Disclaimer of Warranties and Limitation of Liability</strong>
                <br />
                <br />
                THE RXLIGHTNING SERVICES AND ALL INFORMATION, CONTENT, MATERIALS,
                PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR
                OTHERWISE MADE AVAILABLE TO YOU THROUGH THE RXLIGHTNING SERVICES
                ARE PROVIDED BY RXLIGHTNING ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                UNLESS OTHERWISE SPECIFIED IN WRITING. RXLIGHTNING MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
                TO THE OPERATION OF THE RXLIGHTNING SERVICES, OR THE INFORMATION,
                CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER
                SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
                THE RXLIGHTNING SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
                EXPRESSLY AGREE THAT YOUR USE OF THE RXLIGHTNING SERVICES IS AT
                YOUR SOLE RISK.
                <br />
                <br />
                TO THE FULL EXTENT PERMISSIBLE BY LAW, RXLIGHTNING DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                PARTICULAR PURPOSE. RXLIGHTNING DOES NOT WARRANT THAT THE
                RXLIGHTNING SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS
                (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE
                MADE AVAILABLE TO YOU THROUGH THE RXLIGHTNING SERVICES,
                RXLIGHTNING'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM
                RXLIGHTNING ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO
                THE FULL EXTENT PERMISSIBLE BY LAW, RXLIGHTNING WILL NOT BE LIABLE
                FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY
                RXLIGHTNING SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
                PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR
                OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY RXLIGHTNING SERVICE,
                INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
                AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
                <br />
                <br />
                TO THE FULL EXTENT PERMISSIBLE BY LAW, IF, NOTWITHSTANDING THE
                OTHER PROVISIONS OF THESE TERMS OF USE, RXLIGHTNING IS FOUND TO BE
                LIABLE TO YOU, RXLIGHTNING’S LIABILITY WILL NOT EXCEED THE FEES PAID
                BY YOU FOR THE PARTICULAR INFORMATION OR SERVICE PROVIDED.
                <br />
                <br />
                <strong>Disputes</strong>
                <br />
                <br />
                Any dispute or claim relating in any way to your use of any of the RxLightning Services, or to
                any products or services sold or distributed by RxLightning or through RxLightning.com will
                be resolved by binding arbitration, rather than in court, except that you may assert claims in
                small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration
                law apply to these Terms of Use.
                <br />
                <br />
                There is no judge or jury in arbitration, and court review of an arbitration award is limited.
                However, an arbitrator can award on an individual basis the same damages and relief as a court
                (including injunctive and declaratory relief or statutory damages), and must follow the terms of
                these Terms of Use as a court would.
                <br />
                <br />
                To begin an arbitration proceeding, you must send a letter requesting arbitration and describing
                your claim to the RxLightning address provided below. The arbitration will be conducted by
                the American Arbitration Association (AAA) under its rules, including the AAA's
                Supplementary Procedures for Consumer-Related Disputes in Louisville, KY. The AAA's rules
                are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing,
                administration and arbitrator fees will be governed by the AAA's rules. We may choose to have
                the arbitration conducted by telephone, based on written submissions, or at another mutually
                agreed location.
                <br />
                <br />
                We each agree that any dispute resolution proceedings will be conducted only on an individual
                basis and not in a class, consolidated or representative action. If for any reason a claim
                proceeds in court rather than in arbitration, we each waive any right to a jury trial. We also
                both agree that you or we may bring suit in court to enjoin infringement or other misuse of
                intellectual property rights.
                <br />
                <br />
                <strong>Applicable Law</strong>
                <br />
                <br />
                By using any of the RxLightning Services, you agree that the Federal Arbitration Act,
                applicable federal law, and the laws of the state of Delaware, without regard to principles of
                conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise
                between you and RxLightning.
                <br />
                <br />
                <strong>Site Policies and Modification</strong>
                <br />
                <br />
                RxLightning may from time to time adopt policies for the use of our website and the
                RxLightning Services, which will be posted to our website and are incorporated into these
                Terms of Use by this reference. RxLightning reserves the right to make changes to our website,
                those policies, and these Terms of Use at any time.
                <br />
                <br />
                <strong>Severability, Waiver, and Entire Agreement</strong>
                <br />
                <br />
                If any of these terms shall be deemed invalid, void, or for any reason unenforceable, that term
                shall be deemed severable and shall not affect the validity and enforceability of the remaining
                terms. RxLightning’s failure to insist upon strict performance of any provision of these Terms
                of Use will not be construed as an implicit waiver of any provision or right.
                <br />
                <br />
                These Terms of Use constitute the entire agreement between you and RxLightning governing
                your use of the RxLightning Services.
                <br />
                <br />
                <strong>Copyright Infringement Complaints</strong>
                <br />
                <br />
                RxLightning respects the intellectual property of others. If you have a copyright concern,
                please contact us at <a href="mailto:contact@rxlightning.com">Contact@rxlightning.com</a>
                <br />
                <br />
                <strong>Security Privacy Officer </strong>
                <br />
                <br />
                If you have a HIPAA or Covered Information concern, please contact Brad Allen at <a href="mailto:brad.allen@rxlightning.com">brad.allen@rxlightning.com</a>
                <br />
                <br />
                <strong>Questions or Additional Information</strong>
                <br />
                Email: Contact@rxlightning.com
                <br />
                Mailing Address:
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RxLightning Inc
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10028 West Wind Dr
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Greenville IN 47124
            </Typography>
        </Grid>
    </Grid>
  </Box>
  <Footer />
  </>;
}

export default Terms;
