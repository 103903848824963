import React from 'react';
import Drug from './Drug';

function DrugList(props) {
  let drugs = props.filteredDrugs.map((drug) => {
    return <Drug drugName={drug} />;
  });
  return <div>{drugs}</div>;
}

export default DrugList;
