import { Box } from '@mui/material';
import React from 'react';

function Drug(props) {
  return (
    <div>
      <Box>
        <p style={{ fontSize: '1.5rem' }}>{props.drugName}</p>
        <hr
          style={{
            color: '#2F89CC',
            backgroundColor: '#2F89CC',
            height: 2,
            width: '100%',
          }}
        />
      </Box>
    </div>
  );
}

export default Drug;
