import React from 'react';
import {Box, Grid} from '@mui/material';

function Testimonial(props) {
  return (
    <>
      <Grid container>
        <Grid item xs={10} style={{ fontSize: '1.15rem' }}>
          "{props.testimonial}"
          <Box mt={1}>
            <p style={{ fontWeight: 900, margin: 0 }}>{props.name}</p>
          </Box>
          <Box>
            <p style={{ fontWeight: 900, margin: 0 }}>
              {props.company} {props.title}
            </p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Testimonial;
