import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import emailjs from 'emailjs-com';
import {useAlert} from 'react-alert';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactForm() {
  const alert = useAlert();
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_4b3cmsl',
        'default_template',
        e.target,
        'user_57HoFF5BWFBGfertOspEG'
      )
      .then(
        (result) => {
          alert.show(
            'Thanks! We have received your request and will be in contact shortly.',
            {
              closeCopy: 'Close',
            }
          );
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  return (
    <>
      <Box mt="50px" pb={8}>
        <form autoComplete="off" onSubmit={sendEmail}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                name="firstName"
                label="First Name"
                variant="outlined"
                size="small"
                fullWidth
                required
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="lastName"
                label="Last Name"
                variant="outlined"
                size="small"
                fullWidth
                required
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                required
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="company"
                id="company"
                label="Company"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="organizationType"
                id="organizationType"
                label="Organization Type"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  required
                  style={{ fontSize: '1.25rem', fontWeight: 400 }}
                >
                  Inquiry Type
                </FormLabel>
                <RadioGroup aria-label="inquiry" name="inquiryType" required>
                  <FormControlLabel
                    value="generalInformation"
                    control={<Radio />}
                    label="General Information"
                    style={{ marginTop: '10px', color: '#444444' }}
                  />

                  <FormControlLabel
                    value="supportRequest"
                    control={<Radio />}
                    label="Support Request"
                    style={{ color: '#444444' }}
                  />

                  <FormControlLabel
                    value="demo"
                    control={<Radio />}
                    label="Demo"
                    style={{ color: '#444444' }}
                  />

                  <FormControlLabel
                    value="other"
                    control={<Radio required />}
                    label="Other"
                    style={{ color: '#444444' }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                name="note"
                label="Leave us a note:"
                variant="outlined"
                size="large"
                fullWidth
                multiline
                rows={10}
                required
                InputLabelProps={{
                  style: { fontSize: '1.2rem' },
                }}
              />
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <FormGroup aria-label="termsAndConditions" row>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" required={true} />}
                    label={
                      <Box fontSize="0.8rem" color="#444444">
                        By checking this box, you agree to our Terms and
                        Conditions. You consent to RxLightning using your
                        personal data to provide you with sales and service
                        related communications relating to its products and
                        services.
                      </Box>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <ReCAPTCHA
                sitekey="6LcRB3EaAAAAAKk4_QsKHZUgXQzbtqG6pJa9bEja"
                onChange={onChange}
                class="g-recaptcha"
              />
            </Grid>
            <Grid item>
              <input
                type="submit"
                value="SEND"
                style={{
                  height: '60px',
                  width: '100%',
                  fontSize: '1.4rem',
                  backgroundColor: '#0088CC',
                  color: '#fff',
                  cursor: 'pointer',
                }}
              ></input>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
export default ContactForm;
