import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import logo from '../img/rx-logo.png';
import { Icon } from '@iconify/react';
import usersIcon from '@iconify-icons/ph/users';
import heartIcon from '@iconify-icons/cil/heart';
import bxsCapsule from '@iconify-icons/bx/bxs-capsule';
import briefcase from '@iconify-icons/fa-solid/briefcase-medical';
import SignatureCanvas from 'react-signature-canvas';

function PatientReg() {
  // deals with first page
  const [showSecond, setShowSecond] = useState(false);
  // deals with second page
  const [showThird, setShowThird] = useState(true);
  // deals with third page
  const [showLast, setShowLast] = useState(true);

  //   When 'GET STARTED' is clicked
  const handleClickOne = () => {
    setShowSecond(true);
    setShowThird(false);
  };

  // When 'COMPLETE' is clicked
  const handleClickTwo = () => {
    setShowSecond(true);
    setShowThird(true);
    setShowLast(false);
  };

  return (
    <div>
      <Box width="80%" mx="auto" mb="50px">
        <Box mt="50px">
          <img src={logo} alt="rx lightning" />
        </Box>

        {/* First page  */}
        <Box style={{ display: showSecond ? 'none' : '' }}>
          <Box width="50%" mx="auto" mt="80px">
            <Grid container spacing={5}>
              <Grid item>
                <Typography>
                  Your doctor needs your help to get you started on your newly prescribed
                  specialty medication fast!
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Complete the information below to get started:
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box width="30%" mx="auto" mt="50px">
            <Grid container direction="column">
              <Grid item>
                <Typography>Patients Last Name</Typography>
              </Grid>
              <Grid item>
                <TextField variant="outlined" color="primary" />
              </Grid>
            </Grid>
          </Box>
          <Box width="30%" mx="auto" mt="20px">
            <Grid container direction="column">
              <Grid item>
                <Typography>Patients Date of Birth</Typography>
              </Grid>
              <Grid item>
                <TextField variant="outlined" type="date" color="primary" />
              </Grid>
            </Grid>
          </Box>
          <Box width="30%" mx="auto" mt="20px">
            <Button
              variant="contained"
              onClick={handleClickOne}
              style={{
                maxWidth: '400px',
                height: '70px',
                fontSize: '1.2rem',
                marginTop: '10px',
                backgroundColor: '#0088CC',
                color: 'white',
                width: '250px',
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        {/* End of first page  */}

        {/* Second page  */}
        <Box style={{ display: showThird ? 'none' : '' }}>
          <Box width="80%" mt="80px" mx="auto">
            <Box>
              <Grid container spacing={2} justifyContent="center">
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Icon
                        icon={usersIcon}
                        style={{ height: '30px', width: '30px' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontWeight: '700' }}>
                        Lightning McQueen (01-01-1982)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Icon
                        icon={heartIcon}
                        style={{ height: '30px', width: '30px' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontWeight: '700' }}>
                        Doc Hudson
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Icon
                        icon={bxsCapsule}
                        style={{ height: '30px', width: '30px' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontWeight: '700' }}>
                        Gilenya
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Icon
                        icon={briefcase}
                        style={{ height: '30px', width: '30px' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontWeight: '700' }}>Hub</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box width="80%" mx="auto" mt="20px">
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Patient Enrollment:
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  mt="10px"
                  style={{
                    maxHeight: 550,
                    width: '80%',
                    overflow: 'auto',
                    border: '1px solid #E9EEF1',
                  }}
                >
                  <Typography variant="body2" style={{ padding: '10px' }}>
                    I give permission for my health care providers (HCPs),
                    pharmacies, health insurer(s), third par ty contractors, and
                    service providers to disclose my personal information,
                    including information about my insurance, prescriptions,
                    medical condition, and health (“Personal Information”) to
                    Novar tis Pharmaceuticals Corporation, its affiliates,
                    business par tners, and agents (the “Novar tis Group”) and
                    to the Novar tis Patient Assistance Foundation, Inc.
                    (“NPAF”) so that the Novar tis Group and NPAF can (i) help
                    verify or coordinate insurance coverage or otherwise obtain
                    payment for my treatment with GILENYA, (ii) coordinate my
                    receipt of and payment for GILENYA, (iii) facilitate my
                    access to GILENYA, (iv) provide me with information about
                    GILENYA, disease awareness, management programs, and
                    educational materials, (v) manage the GILENYA Go Program,
                    (vi) provide me with adherence reminders and suppor t, (vii)
                    conduct quality assurance, surveys, and other internal
                    business activities in connection with the GILENYA Go
                    Program, and (viii) if I choose to apply to programs offered
                    by the NPAF, to administer those programs, to send me
                    information about programs that might help me pay for my
                    medicines, and to coordinate and share my Personal
                    Information with my health care providers, other programs
                    that might help me pay for medicines, government agencies,
                    and insurance companies for purposes of providing or
                    facilitating this assistance.
                    <br />
                    <br /> I give permission to the Novar tis Group and NPAF to
                    disclose my Personal Information to my health care
                    providers, pharmacies, health insurer(s), caregivers, and
                    other third-par ty contractors or service providers for the
                    purposes described above. I also give permission to the
                    Novar tis Group and NPAF to combine or aggregate any
                    information collected from me with information the Novar tis
                    Group and NPAF may collect about me from other sources for
                    the purpose of providing or administering Program services.I
                    understand that my pharmacy, health insurer(s), and health
                    care providers may receive remuneration (payment) from the
                    Novar tis Group in exchange for disclosing my personal
                    information to the Novar tis Group and/or for providing me
                    with therapy support services.I understand that once my
                    Personal Information is disclosed it may no longer be
                    protected by federal and state privacy law. I understand
                    that I may refuse to sign this authorization. I also may
                    revoke (withdraw) this authorization with respect to the
                    GILENYA Go Program at any time in the future by calling
                    1-888-NOW-NOVA (1-888-669-6682) or by writing to the
                    Customer Interaction Center, Novar tis Pharmaceuticals
                    Corporation, One Health Plaza, East Hanover, NJ 07936-1080.
                    I also may revoke (withdraw) this authorization with respect
                    to NPAF at any time in the future by calling 1-800-277-2254.{' '}
                    <br />
                    <br />
                    My refusal or future revocation will not affect the
                    commencement or continuation of my treatment by my doctors;
                    however, if I revoke this authorization, I may no longer be
                    able to par ticipate in the GILENYA Go Program and/or
                    programs administered by NPAF. If I revoke this
                    authorization, the Novar tis Group and/or NPAF will stop
                    using or sharing my information (except as necessary to end
                    my par ticipation in the program and/or NPAF) but my
                    revocation will not affect uses and disclosures of Personal
                    Information previously disclosed in reliance upon this
                    authorization. I understand that this authorization will
                    remain valid for five (5) years after the date of my
                    signature, unless I revoke it earlier. I also understand
                    that the GILENYA Go Program and/or programs administered by
                    NPAF may change or end at any time without prior
                    notification. I understand that I may receive a copy of this
                    authorization. <br />
                    <br />I agree to be contacted by the Novartis Group and NPAF
                    by mail, e-mail, telephone calls, and text messages at the
                    number(s) and address(es) provided on the Star t Form for
                    all purposes described in this Patient Authorization. I also
                    agree to be contacted by the Novar tis Group, NPAF, and
                    others on its behalf by telephone calls and text messages
                    made by or using an automatic telephone dialing system or
                    pre-recorded voice, at the number(s) provided on this form,
                    for all non-marketing purposes, including but not limited to
                    sending me materials and asking for my par ticipation in
                    surveys. I confirm that I am the subscriber for the
                    telephone number(s) provided and the authorized user for the
                    e-mail address(es) provided, and I agree to notify the Novar
                    tis Group and/or NPAF promptly if any of my numbers or
                    addresses change in the future. I understand that my
                    wireless service provider’s message and data rates may
                    apply. <br />
                    <br /> I understand that the Novar tis Group and NPAF do not
                    permit my Personal Information to be used by its business
                    par tners for their own separate marketing purposes. I
                    understand and agree that Personal Information transmitted
                    by e-mail and cell phone cannot be secured against
                    unauthorized access.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Telephone Consumer Protection Act (TCPA) Consent:
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  mt="10px"
                  style={{
                    maxHeight: 550,
                    width: '80%',
                    overflow: 'auto',
                  }}
                >
                  <Typography variant="body2">
                    I consent to receive marketing calls and texts from and on
                    behalf of Novar tis Pharmaceuticals Corporation, made with
                    an auto dialer or prerecorded voice, at the phone number(s)
                    provided. I understand that my consent is not required or a
                    condition of purchase. I agree to the TCPA Terms &
                    Conditions. Number of messages will vary based on your
                    program selections. Message and data rates may apply. Text
                    STOP to opt out and HELP for help.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Co-Pay Assistance Program Terms and Conditions:
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  mt="10px"
                  style={{
                    maxHeight: 550,
                    width: '80%',
                    overflow: 'auto',
                  }}
                >
                  <Typography variant="body2">
                    Limitations apply. Up to a $18,000 annual limit. Offer not
                    valid under Medicare, Medicaid, or any other federal or
                    state program. Novar tis reserves the right to rescind,
                    revoke, or amend this program without notice. Limitations
                    may apply in MA and CA. See complete Terms & Conditions for
                    details at GILENYA.com.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography
                  style={{ fontWeight: '700', marginTop: '40px', width: '80%' }}
                >
                  Novartis Patient Assistance Foundation, Inc. (NPAF) and Fair
                  Credit Reporting Act (FCRA) Authorization:
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  mt="10px"
                  style={{
                    maxHeight: 550,
                    width: '80%',
                    overflow: 'auto',
                  }}
                >
                  <Typography variant="body2">
                    I understand that I am providing “written instructions”
                    authorizing NPAF and its vendor, under the FCRA, to obtain
                    information from my credit profile or other information from
                    Experian Health, solely for the purpose of determining
                    financial qualifications for programs administered by NPAF.
                    I understand that I must affirmatively agree to these terms
                    in order to proceed in this financial screening process. I
                    promise that any information, including financial and
                    insurance information that I provide are complete and true
                    and, unless I have indicated otherwise, I have no drug
                    insurance coverage, which includes Medicaid, Medicare, or
                    any public or private assistance programs or any other form
                    of insurance. If my income or health coverage changes, I
                    will call NPAF at 1-800-277-2254. If eligible, I would like
                    to be considered for programs administered by NPAF.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Is it OK to leave a message about Gilenya on:
                </Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender1" >
                    <FormControlLabel value="cell phone" control={<Radio />} label="Cell phone" />
                    <FormControlLabel value="home phone" control={<Radio />} label="Home phone" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  I have read and agree to the attached Patient Authorization.
                </Typography>
              </Grid>
              <Grid item>
                <Box mt="20px">
                  <SignatureCanvas
                    canvasProps={{
                      width: '700px',
                      height: '200px',
                      className: 'sigCanvas',
                    }}
                    backgroundColor="#E9EEF1"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Date of Signature:
                </Typography>
              </Grid>
              <Grid item>
                <TextField variant="outlined" type="date" color="primary" />
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '700', marginTop: '40px' }}>
                  Check all that apply:
                </Typography>
              </Grid>
              <Grid item>
                <Box mt="20px">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Checkbox />
                    </Grid>
                    <Grid item md={11} xs={8}>
                      <Typography variant="subtitle1">
                        I have read and agree to the Terms and Conditions for
                        participation in the GILENYA Co-Pay Assistance Program.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box mt="20px">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Checkbox />
                    </Grid>
                    <Grid item md={11} xs={8}>
                      <Typography variant="subtitle1">
                        I have read and agree to receive text messages and calls
                        as explained in the Telephone Consumer Protection Act
                        (TCPA) consent. (optional)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box mt="20px">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Checkbox />
                    </Grid>
                    <Grid item md={11} xs={8}>
                      <Typography variant="subtitle1">
                        I have read and agree to the Novartis Patient Assistance
                        Foundation (NPAF) and Fair Credit Reporting Act
                        Authorization. (optional)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box mx="auto" mt="20px">
                  <Button
                    variant="contained"
                    onClick={handleClickTwo}
                    style={{
                      maxWidth: '400px',
                      height: '70px',
                      fontSize: '1.2rem',
                      marginTop: '10px',
                      backgroundColor: '#0088CC',
                      color: 'white',
                      width: '250px',
                    }}
                  >
                    Complete
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* End of second page  */}

        {/* Third page  */}
        <Box style={{ display: showLast ? 'none' : '' }}>
          <Box width="80%" mt="80px" mx="auto">
            <Typography variant="h1">
              Thank you! We are looking forward to helping your care team get
              you started on therapy as quickly as possible.
            </Typography>
          </Box>
        </Box>
        {/* End of third page  */}
      </Box>
    </div>
  );
}

export default PatientReg;
