export const MenuItems = [
  {
    title: 'About RxLightning',
    path: '/about',
    cName: 'dropdownLink',
  },
  {
    title: 'News',
    path: '/news',
    cName: 'dropdownLink',
  }
];
