import { Box, Button, Grid } from '@mui/material';
import React from 'react';

function CTAButton() {
  return <>
    <Box width="100%" bgcolor="#E9EEF1" textAlign="center" py="150px">
      <Grid container justifyContent="center">
        <Grid item sm={8} xs={10}>
          <Box
            fontWeight="600"
            fontSize="2.5rem"
            color="#444444"
            className="awardHeading"
          >
            Get your patients started on the specialty medications they need
            with RxLightning speed
          </Box>
        </Grid>
      </Grid>
      <Box width={3 / 4} mx="auto" mt={4}>
        <a
          href={process.env.REACT_APP_REGISTRATION_URL}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            style={{
              maxWidth: '400px',
              height: '70px',
              fontSize: '1.2rem',
              marginTop: '10px',
              backgroundColor: '#0088CC',
              color: 'white',
            }}
          >
            CREATE YOUR FREE ACCOUNT
          </Button>
        </a>
      </Box>
    </Box>
  </>;
}

export default CTAButton;
