import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100px;
  color: black;
  margin: 0 15px;
  font-size: 4em;
`;
