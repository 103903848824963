import React from 'react';
import { Box, Grid, Typography, Hidden } from '@mui/material';
import Checklist from './Checklist';
import Testimonial from './Testimonial';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function HeroInfo(props, { threeChecks, fourChecks }) {
  const isList = props.isList;
  const isLink = props.isLink;
  return <>
    <Box my="150px" mx="auto" width="100%">
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction={props.direction}
      >
        <Grid item align="left" lg={4} md={5} sm={5} xs={10}>
          <Box
            fontWeight="300"
            fontSize="1.5rem"
            color="gray"
            style={{ letterSpacing: '0.2rem' }}
          >
            {props.subtitle}
          </Box>
          <Box
            fontWeight="fontWeightMedium"
            fontSize="2.5rem"
            color={props.titleColor}
            width={props.width}
          >
            {props.title1}
          </Box>
          <Box fontWeight="fontWeightMedium" fontSize="1.5rem">
            {props.title2}
          </Box>
          <Box fontWeight="fontWeightMedium" fontSize="1.5rem">
            {props.title3}
          </Box>
          <Box
            style={{ color: '#444444', fontSize: '1.4rem' }}
            width={props.descWidth}
          >
            <p>{props.desc}</p>
          </Box>
          {isList ? (
            <Checklist
              threeChecks={props.threeChecks}
              fourChecks={props.fourChecks}
              item1={props.item1}
              item2={props.item2}
              item3={props.item3}
              item4={props.item4}
              bold1={props.bold1}
              bold2={props.bold2}
              bold3={props.bold3}
              boldColor={props.boldColor}
            />
          ) : (
            <Testimonial
              testimonial={props.testimonial}
              name={props.name}
              company={props.company}
              title={props.title}
            />
          )}

          <Box mt={5} mb={2} width="100%">
            <Typography variant="h6" color="primary">
              <a
                href={props.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#0088CC',
                  fontWeight: 400,
                  fontSize: '1rem',
                }}
              >
                {props.link}
                {isLink ? <ArrowForwardIcon fontSize="inherit" /> : ''}
              </a>
            </Typography>
          </Box>
        </Grid>
        <Hidden>
          <Grid item md={4} sm={3}>
            <img src={props.image} width="100%" alt={props.altdesc} />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  </>;
}

export default HeroInfo;
