import {Box, Grid, Hidden, Typography} from '@mui/material';
import React from 'react';
import CTAButton from '../components/CTAButton';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';
import TherapyCarousel from '../components/TherapyCarousel';
import TherapySearch from '../components/TherapySearch';

function Therapies() {
  return <>
    {/* Top section  */}
    <Box mt="100px" width="100%">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item md={6} sm={10}>
          <Typography variant="h1">
            <Box
              fontWeight="600"
              color="#444444"
              style={{ lineHeight: '4.5rem' }}
              className="therapyHeading"
            >
              One place to go to handle all your specialty drug enrollment
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          align="center"
          lg={2}
          md={3}
          sm={12}
          className="awardMargin"
        >
          <img src="https://cdn.rxlightning.com/images/heart-bolt.png" height="350" alt="rxlightning heart bolt" />
        </Grid>
      </Grid>
    </Box>

    {/* Digitzed forms section  */}
    <Box mt="120px" mx="auto" py={5}>
      <Grid container alignItems="center" justifyContent="space-evenly">
        <Grid item align="center" lg={8} md={8} sm={4} xs={10}>
          <Box fontWeight="600" fontSize="2.5rem" color="#444444">
            Digitized forms for 900+ specialty medications
          </Box>
          <Box mt="50px">
            <Typography variant="body1" textAlign="center">
              <p style={{ fontSize: '1.6rem' }}>
                We offer a single destination for healthcare providers to
                enroll patients for any specialty medication, brand or
                generic, at any specialty pharmacy. We have digitized forms for
                900+ specialty drugs and hubs with built-in custom logic,
                smart-pick menus and real time updates to ensure the right
                enrollment information the first time, every time for every
                medication.
              </p>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>

    {/* Therapies we support section  */}
    <Box my="100px" py="100px" mx="auto" textAlign="center">
      <Box fontWeight="600" fontSize="2.5rem" color="#444444">
        Specialties we support
      </Box>
      <Box mt="25px">
        <TherapyCarousel />
      </Box>
    </Box>

    {/* A - Z section  */}
    <Hidden mdDown>
      <Box my={8} py="150px" bgcolor="#E9EEF1" mx="auto" textAlign="center">
        <Box fontWeight="600" fontSize="2.5rem" color="#444444">
          We've got you covered from A-Z
        </Box>
        <p
          style={{
            fontSize: '1.6rem',
            fontWeight: '400',
            color: '#444444',
            marginTop: '40px',
          }}
        >
          Search for an enrollment form by therapy or with the starting letter
          of the medication.
        </p>

        <Box mt="50px">
          <TherapySearch />
        </Box>
      </Box>
    </Hidden>

    <CTAButton />
    <InfoFooter />
    <Footer />
  </>;
}

export default Therapies;
