import { Box, Button, Hidden, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return <>
    <Box width="100%" bgcolor="#E8EEF1" textAlign="center" py="50px">
      <Typography variant="caption">
        <Box width={3 / 7} mx="auto" fontWeight="fontWeightRegular">
          <Button
            component={Link}
            to="/terms"
            style={{
              fontSize: '1rem',
              fontWeight: 400,
              textTransform: 'none',
            }}
          >
            Terms & Privacy
          </Button>
          <Hidden smDown>|</Hidden>
          <Button
            component={Link}
            to="/privacy"
            style={{
              fontSize: '1rem',
              fontWeight: 400,
              textTransform: 'none',
            }}
          >
            Privacy
          </Button>
          <Hidden smDown>|</Hidden>
          <Button
            component={Link}
            to="/contact"
            style={{
              fontSize: '1rem',
              fontWeight: 400,
              textTransform: 'none',
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Typography>
      <Box width={3 / 4} mx="auto" mt={2}>
        <Typography variant="caption">
          <Box fontSize="1rem">
            &copy;2023 RxLightning, Inc. All rights reserved.
          </Box>
        </Typography>
      </Box>
    </Box>
  </>;
}

export default Footer;
