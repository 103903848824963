import {
  Box,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Hidden,
} from '@mui/material';
import React from 'react';
import logo from '../img/rx-logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import '../pages/css/home.css';

function InfoFooter() {
  return <>
    <Box
      width="75%"
      my={5}
      py="40px"
      textAlign="center"
      className="socialSection"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        {/* Info Block  */}
        <Grid item>
          {/* Rx Logo  */}
          <Box>
            <Grid container>
              <Grid item>
                <Link to="/">
                  <img src={logo} width="200" alt="rx lightning logo" />
                </Link>
              </Grid>
            </Grid>
          </Box>
          {/* Social Links  */}
          <Box mt={2}>
            <Grid container spacing={6}>
              <Grid item md={1} hidden>
                <a
                  href="https://www.facebook.com/RxLightningInc"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon
                    color="primary"
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: '-5px',
                    }}
                  />
                </a>
              </Grid>
              <Grid item md={1} hidden>
                <a
                  href="https://twitter.com/rxlightning?lang=en"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon
                    color="primary"
                    style={{ width: '40px', height: '40px' }}
                  />
                </a>
              </Grid>
              <Grid item md={1}>
                <a
                  href="https://www.linkedin.com/company/rxlightning/"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon
                    color="primary"
                    style={{ width: '40px', height: '40px' }}
                  />
                </a>
              </Grid>
            </Grid>
          </Box>
          {/* Contact Info  */}
          <Box fontWeight="fontWeightMedium" fontSize="1rem" mt={2}>
            <Grid container direction="column">
              <Box mb={1}>
                <Grid item>
                  <Grid container alignItems="center">
                    <PhoneIcon fontSize="large" color="primary" />
                    <Typography variant="caption">
                      <a
                        href="tel:1-855-485-0579"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Box fontSize="1.1rem">
                          &nbsp;&nbsp;1-855-485-0579
                        </Box>
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Grid item>
                <Grid container alignItems="center">
                  <BusinessIcon fontSize="large" color="primary" />
                  <Typography variant="caption">
                    <Box fontSize="1.1rem">&nbsp;&nbsp;New Albany, IN</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Link Block  */}
        <Hidden smDown>
          <Grid item>
            <Box textAlign="left">
              <Grid container direction="column">
                <Grid item>
                  <Box fontSize="1.2rem" fontWeight="500" mb={2}>
                    Navigate
                  </Box>
                </Grid>
                <Grid item style={{ marginLeft: '-9px' }}>
                  <ButtonGroup
                    orientation="vertical"
                    color="primary"
                    aria-label="vertical contained primary button group"
                    // variant="text"
                    size="small"
                  >
                    <Link to="/" style={{ textDecoration: 'none' }}>
                      <Button
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          textTransform: 'none',
                        }}
                      >
                        Home
                      </Button>
                    </Link>
                    <Link to="/about" style={{ textDecoration: 'none' }}>
                      <Button
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          textTransform: 'none',
                        }}
                      >
                        About
                      </Button>
                    </Link>
                    <Link to="/therapies" style={{ textDecoration: 'none' }}>
                      <Button
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          textTransform: 'none',
                        }}
                      >
                        For Providers
                      </Button>
                    </Link>
                    <a
                      href={process.env.REACT_APP_LOGIN_URL}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          textTransform: 'none',
                          paddingLeft: 5,
                        }}
                      >
                        Login
                      </Button>
                    </a>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                      <Button
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          textTransform: 'none',
                          paddingLeft: 9,
                        }}
                      >
                        Contact Us
                      </Button>
                    </Link>
                    <Link to={{pathname: "https://jobs.gusto.com/boards/rxlightning-inc-2ba76134-6085-4789-bec7-35312efef93e"}} target="_blank"  style={{ textDecoration: 'none' }}>
                      <Button
                          style={{
                            fontSize: '1rem',
                            fontWeight: 400,
                            textTransform: 'none',
                            paddingLeft: 9,
                          }}
                      >
                        Careers
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Hidden>

        {/* Button Block  */}
        <Hidden smDown>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Box fontSize="1.2rem" fontWeight="500">
                  Create your free account.
                </Box>
              </Grid>
              <Grid item>
                <Box width={3 / 4} mx="auto" mt={2}>
                  <a
                    href={process.env.REACT_APP_REGISTRATION_URL}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        height: '60px',
                        fontSize: '1.2rem',
                        marginTop: '10px',
                        width: '190px',
                        marginLeft: '-40px',
                      }}
                    >
                      GET STARTED
                    </Button>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  </>;
}

export default InfoFooter;
