import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Button, Grid, Typography } from '@mui/material';
import Item from './Item';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './TherapyCarousel.css';
import '../img/icons/fontawesome-pro/css/all.css';

function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
  return (
    <Button onClick={onClick} disabled={isEdge}>
      {pointer}
    </Button>
  );
}

function TherapyCarousel() {
  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 5, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 7 },
  ];
  return (
    <div>
      <div></div>
      <Carousel
        itemsToShow={4}
        renderArrow={myArrow}
        breakPoints={breakpoints}
        pagination={true}
        itemPadding={[50, 50, 50, 50]}
        enableAutoPlay={true}
      >
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-capsules"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Addiction Medicine</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-inhaler"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Allergy & Asthma</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-heartbeat"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Cardiology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-allergies"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Dermatology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-user"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Endocrinology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-stomach"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Gastroenterology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-dna"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Genetic Disorders</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-tint"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Hematology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-diagnoses"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Immunology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-biohazard"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Infectious Disease</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-kidneys"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Nephrology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-head-side-brain"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Neurology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-ribbon"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Oncology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-eye"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Ophthalmology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-bone"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Orthopedic</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-baby"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Pediatrics</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-lungs"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Pulmonology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-syringe"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Rheumatology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-medkit"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Transplant</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-burn"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Urology</Typography>
            </Grid>
          </Grid>
        </Item>
        <Item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <i
                className="fas fa-female"
                style={{ color: '#2F89CC', fontSize: '5rem' }}
              ></i>
            </Grid>
            <Grid item>
              <Typography variant="h5">Women's Health</Typography>
            </Grid>
          </Grid>
        </Item>
      </Carousel>
    </div>
  );
}

export default TherapyCarousel;
