import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Hidden} from '@mui/material';
import Dropdown from './Dropdown';
import './Navbar.css';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logo from '../img/rx-logo.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return <>
    <nav className="navbar">
      <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
        <img src={logo} alt="rxlightning logo" width="270" height="70" />
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        {click ? (
          <CloseIcon fontSize="large" style={{ color: '#fff' }} />
        ) : (
          <MenuIcon color="primary" fontSize="large" />
        )}
      </div>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li
          className="nav-item"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
            About{' '}
            <Hidden lgDown>
              <KeyboardArrowDownIcon fontSize="inherit" />
            </Hidden>
          </Link>
          {dropdown && <Dropdown />}
          {/* <Dropdown /> */}
        </li>
        <li className="nav-item">
          <Link
            to="/solution"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            Solution
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
            Contact
          </Link>
        </li>
        <li className="nav-item">
          <Link to={{pathname: "https://jobs.gusto.com/boards/rxlightning-inc-2ba76134-6085-4789-bec7-35312efef93e"}} target="_blank" className="nav-links" onClick={closeMobileMenu}>
            Careers
          </Link>
        </li>
        <Hidden mdUp>
          <li className="nav-item">
            <a
              href={process.env.REACT_APP_REGISTRATION_URL}
              target="_blank"
              rel="noreferrer"
              className="nav-links"
            >
              GET STARTED
            </a>
          </li>
          <li className="nav-item">
            <a
              href={process.env.REACT_APP_LOGIN_URL}
              target="_blank"
              rel="noreferrer"
              className="nav-links"
            >
              Login
            </a>
          </li>
        </Hidden>
      </ul>
      <a
        href={process.env.REACT_APP_REGISTRATION_URL}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            height: '50px',
            fontSize: '1.2rem',
            fontWeight: '600',
            letterSpacing: '0.2rem',
          }}
          className="desktopBtn"
        >
          GET STARTED
        </Button>
      </a>
      <a
        href={process.env.REACT_APP_LOGIN_URL}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button
          color="primary"
          variant="outlined"
          style={{
            marginLeft: '18px',
            height: '50px',
            fontSize: '1.2rem',
            fontWeight: '600',
            letterSpacing: '0.2rem',
          }}
          className="desktopBtn"
          size="large"
        >
          LOGIN
        </Button>
      </a>
    </nav>
  </>;
}

export default Navbar;
