import { createTheme, adaptV4Theme } from '@mui/material';

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#2F89CC',
      light: '#00B2E3',
      dark: '#003764',
      red: '#D8315B',
    },
    secondary: {
      main: '#0088CC',
      light: '#345D8D',
      dark: '#13293D',
    },
    background: {
      default: '#fff',
    },
    grey: {
      main: '#444444',
    },
    white: {
      main: '#ffffff',
    },
    divider: '##2F89CC',
  },
  shadows: ['none'],
  typography: {
    fontFamily: "Roboto",
    htmlFontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontWeight: 900,
      fontSize: '3rem',
      textAlign: 'center',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'fontWeightRegular',
    },
    body1: {
      fontSize: '1.4rem',
    },
  },
}));

export default theme;
