import {Box, Button, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, Typography,} from '@mui/material';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ReactPlayer from 'react-player';
import CTAButton from '../components/CTAButton';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';
import HeroInfo from '../components/HeroInfo';
import AddIcon from '@mui/icons-material/Add';
import faqdata from '../data/faqquestions.json';
import RemoveIcon from '@mui/icons-material/Remove';
import {ArrowForward} from '@mui/icons-material';
import '../img/icons/fontawesome-pro/css/all.css';

const styles = {
  paperContainer: {
    backgroundImage: `url(https://cdn.rxlightning.com/images/website/banner/happywoman.png)`,
    height: '500px',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff',
    textAlign: 'center',
    display: 'grid',
  },
};

function Solution() {
  const [clickedIndex, setClickedIndex] = useState({});
  const [displayAnswer, setDisplayAnswer] = useState(faqdata[0].Answer);
  const [displayQ, setQ] = useState(faqdata[0].Question);
  const [link, setLink] = useState();
  const [bullet1, setbullet1] = useState();
  const [bullet2, setbullet2] = useState();
  const [bullet3, setbullet3] = useState();
  const [bullet4, setbullet4] = useState();
  const [bullet5, setbullet5] = useState();
  const [register, setRegister] = useState();
  const [show, setShow] = useState(true);

  const handleClick = (index) => () => {
    setClickedIndex((state) => ({
      [index]: !state[index],
    }));
    setDisplayAnswer(faqdata[index].Answer);
    setQ(faqdata[index].Question);
    setLink(faqdata[index].Link);
    setShow(!clickedIndex[index]);
    setbullet1(faqdata[index].Bullet1);
    setbullet2(faqdata[index].Bullet2);
    setbullet3(faqdata[index].Bullet3);
    setbullet4(faqdata[index].Bullet4);
    setbullet5(faqdata[index].Bullet5);
    setRegister(faqdata[index].RegisterLink);
    console.log(displayAnswer);
  };

  return <>
    <Box style={{ overflowX: 'hidden' }}>
      {/* Top section  */}
      <Hidden mdDown>
        <Box style={styles.paperContainer} mt={4}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h1">
                <Box
                  fontWeight="600"
                  color="#fff"
                  lineHeight="75px"
                  width="75%"
                  mx="auto"
                >
                  Get your patients started on the specialty medication they
                  need with RxLightning speed.
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      {/* Video section  */}
      <Box mx="auto" py="120px">
        <Box mx="auto">
          <Grid
            container
            alignItems="center"
            justifyContent="space-evenly"
            direction="column"
          >
            <Grid item align="center" lg={12} md={6} sm={4}>
              <Box
                width="50%"
                fontWeight="600"
                fontSize="2.5rem"
                color="#444444"
                className="solutionAlign"
              >
                Digital, seamless and consolidated pharmacy and hub enrollment
              </Box>

              <Box
                fontSize="1.5rem"
                textAlign="center"
                color="#444444"
                my="40px"
                width="68%"
              >
                <p>
                  Our platform is a single solution that simplifies,
                  streamlines and automates the complex manual process of
                  specialty medication enrollment across manufacturers, hubs
                  and specialty pharmacies.
                </p>
              </Box>
            </Grid>
            <Hidden mdDown>
              <Grid item xl={12}>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=kgJO4R6r_no"
                  width="1000px"
                  height="600px"
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <ReactPlayer url="https://www.youtube.com/watch?v=kgJO4R6r_no" width="100%" />
            </Hidden>
            <Grid item>
              <Box mx="auto" mt={4} textAlign="center">
                <a
                  href={process.env.REACT_APP_REGISTRATION_URL}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      height: '60px',
                      fontSize: '1.25rem',
                      marginTop: '30px',
                      width: '350px',
                    }}
                  >
                    GET STARTED NOW
                  </Button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <hr style={{ width: '80%' }} />

      {/* RxLightning Experience section  */}
      <Box
        mt="150px"
        textAlign="center"
        fontWeight="400"
        fontSize="2.5rem"
        color="#444444"
        className="experiencePadding"
      >
        The RxLightning Experience: How It Works
      </Box>
      <Box mb={4}>
        <HeroInfo
          title1="Registration and Patient Records"
          titleColor="#444444"
          isList={true}
          url="#"
          bold1="Create account: "
          boldColor="#444444"
          item1="Register users and link to prescribers complete with Business Associate Agreements."
          bold2="Upload Patient Profiles: "
          item2="Complete patient profiles with demographics, insurance info and medical history."
          image="https://cdn.rxlightning.com/images/website/portal-patient-reg_opt.jpg"
        />
        <HeroInfo
          title1="Complete Enrollment Process"
          titleColor="#444444"
          isList={true}
          url="#"
          bold1="Specialty pharmacy &#38; hub enrollment: "
          boldColor="#444444"
          item1="Select drug by medication name. The system automatically identifies limited distribution networks, captures patient consent and completes enrollment in one simple process."
          bold2="REMs Program Tracking: "
          item2="Understand the details of REMs program if a medication requires one to ensure medication compliance and safety."
          bold3="Financial assistance programs: "
          item3="Provide access to the patient assistance program guidelines for enrollment and have the ability to complete application process."
          image="https://cdn.rxlightning.com/images/website/portal-sig_opt.jpg"
          direction="row-reverse"
          threeChecks={true}
        />
        <HeroInfo
          title1="Track and Monitor"
          titleColor="#444444"
          isList={true}
          url="#"
          bold1="Simple reauthorizations: "
          boldColor="#444444"
          item1="Submit simplified reauthorization enrollments by verifying and editing past enrollments rather than starting over."
          bold2="Task list: "
          item2="Monitor progress throughout entire process with the ability to easily continue work on a patient enrollment and follow up when necessary to ensure efficiency."
          image="https://cdn.rxlightning.com/images/website/portal-patients_opt.jpg"
        />
      </Box>
      <Box mx="auto" my="100px" textAlign="center">
        <a
          href={process.env.REACT_APP_REGISTRATION_URL}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              height: '60px',
              fontSize: '1.3rem',
              marginTop: '10px',
              maxWidth: '400px',
            }}
          >
            CREATE YOUR FREE ACCOUNT
          </Button>
        </a>
      </Box>

      {/* Therapies we support section  */}
      <Box width="100%" bgcolor="#E9EEF1">
        <Grid container justifyContent="center">
          <Grid item md={6} sm={6} xs={12} className="socialSection">
            <Grid container direction="column">
              <Grid item>
                <Box
                  fontWeight="400"
                  fontSize="2.5rem"
                  color="#444444"
                  mt="50px"
                >
                  Therapies We Support
                </Box>
              </Grid>
              <Grid item>
                <Box
                  width="72%"
                  fontWeight={400}
                  fontSize="1.1rem"
                  color="#444444"
                  className="socialSection"
                >
                  <p>
                    RxLightning is a single platform that supports over 900 therapies,
                    from Actemra to Zytiga.
                  </p>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={1} mb={2}>
                  <Typography variant="h6">
                    <Link
                      to="/therapies"
                      color="primary"
                      style={{
                        color: '#0088CC',
                        fontWeight: 400,
                        fontSize: '1rem',
                      }}
                    >
                      View all therapies&nbsp;&nbsp;
                      <ArrowForward />
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container>
              <Grid
                container
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: '5px' }}
              >
                <Grid item>
                  <i
                    className="fas fa-allergies"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      border: '20px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
                <Grid item>
                  <i
                    className="fas fa-head-side-brain"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      borderLeft: '60px solid #fff',
                      borderBottom: '20px solid #fff',
                      borderTop: '20px solid #fff',
                      borderRight: '60px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: '5px' }}
              >
                <Grid item>
                  <i
                    className="fas fa-lungs"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      borderLeft: '53px solid #fff',
                      borderBottom: '20px solid #fff',
                      borderTop: '20px solid #fff',
                      borderRight: '53px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
                <Grid item>
                  <i
                    className="fas fa-dna"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      border: '20px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{ paddingBottom: 0 }}
                spacing={2}
              >
                <Grid item>
                  <i
                    className="fas fa-heartbeat"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      border: '20px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
                <Grid item>
                  <i
                    className="fas fa-eye"
                    style={{
                      color: '#2F89CC',
                      fontSize: '3.7rem',
                      borderLeft: '54px solid #fff',
                      borderBottom: '20px solid #fff',
                      borderTop: '20px solid #fff',
                      borderRight: '54px solid #fff',
                      backgroundColor: '#fff',
                    }}
                  ></i>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* FAQ Section  */}
      <Box py={4} width="70%" mx="auto">
        <Hidden mdDown>
          <Box
            textAlign="center"
            fontWeight="400"
            fontSize="2.5rem"
            color="#444444"
            mt="80px"
          >
            Frequently Asked Questions
          </Box>
          <Box my={6} width="100%" mx="auto">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <List
                  style={{
                    maxHeight: 550,
                    width: 400,
                    overflow: 'auto',
                    border: '1px solid #E9EEF1',
                  }}
                >
                  {faqdata.map((item, index) => (
                    <ListItem
                      style={{ cursor: 'pointer' }}
                      onClick={handleClick(index)}
                    >
                      <ListItemIcon>
                        {clickedIndex[index] ? (
                          <RemoveIcon color="primary" />
                        ) : (
                          <AddIcon color="primary" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={item.Question} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              {show ? (
                <Grid item>
                  <List
                    style={{
                      maxHeight: 430,
                      maxWidth: 500,
                      overflow: 'auto',
                      border: '1px solid #E9EEF1',
                    }}
                  >
                    <ListItem style={{ fontSize: '1rem' }}>
                      <Box>
                        <Box fontSize="1.2rem" fontWeight="600">
                          {displayQ}
                        </Box>
                        <br />
                        {displayAnswer}
                        <a
                          href="mailto:support@rxlightning.com"
                          style={{
                            color: '#0088CC',
                            fontWeight: 400,
                            fontSize: '1rem',
                          }}
                        >
                          {link}
                        </a>
                        {bullet1 ? (
                          <>
                            <br />
                            <br />
                            {bullet1}
                          </>
                        ) : (
                          ''
                        )}
                        {bullet2 ? (
                          <>
                            <br />
                            <br />
                            {bullet2}
                          </>
                        ) : (
                          ''
                        )}
                        {bullet3 ? (
                          <>
                            <br />
                            <br />
                            {bullet3}
                          </>
                        ) : (
                          ''
                        )}
                        {bullet4 ? (
                          <>
                            <br />
                            <br />
                            {bullet4}
                          </>
                        ) : (
                          ''
                        )}
                        {bullet5 ? (
                          <>
                            <br />
                            <br />
                            {bullet5}
                          </>
                        ) : (
                          ''
                        )}
                        <br />
                        <br />
                        {register ? (
                          <>
                            <Typography variant="h6">
                              <a
                                href={process.env.REACT_APP_REGISTRATION_URL}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: '#0088CC',
                                  fontWeight: 400,
                                  fontSize: '1rem',
                                }}
                              >
                                Begin Registration
                              </a>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant="h6">
                              <Link
                                to="/contact"
                                color="primary"
                                style={{
                                  color: '#0088CC',
                                  fontWeight: 400,
                                  fontSize: '1rem',
                                }}
                              >
                                Have more questions? Send us a message.
                              </Link>
                            </Typography>
                          </>
                        )}
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Hidden>
      </Box>

      <CTAButton />
      <InfoFooter />
      <Footer />
    </Box>
  </>;
}

export default Solution;
