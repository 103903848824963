import React, {useState} from 'react';
import {Box, Button, FormControl, Grid, MenuItem, Select, TextField,} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';
import DrugData from '../data/therapydrugs.json';
import DrugList from './DrugList';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    paddingTop: '20px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  letterBox: {
    border: '2px solid #0088CC',
    fontSize: '2rem',
    width: '80px',
    backgroundColor: '#fff',
    color: '#444444',
    fontWeight: 'bold',
    borderLeft: '2px solid #0088CC',
    '&:focus': {
      backgroundColor: '#0088CC',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#0088CC',
      color: '#fff',
      '&:selected': {
        backgroundColor: '#0088CC',
        color: '#fff',
      },
    },
  },
}));

function TherapySearch() {
  const classes = useStyles();
  const [therapy, setTherapy] = useState('');
  const [state, setState] = useState('');
  const [letter, setLetter] = useState('A');

  // Handles reset button
  const handleClick = () => {
    setState('');
    setLetter('');
    setTherapy('');
  };

  //   Handles input for entering text in search bar
  const handleInput = (e) => {
    setState(e.target.value);
    setLetter('');
    setTherapy('');
  };

  //   Handles drop down list
  const handleChange = (event) => {
    setTherapy(event.target.value);
    console.log(event.target.value);
  };

  //   Handles clear anytime the dropdown list is clicked
  const handleClear = () => {
    setState('');
  };

  //   Handles button letter click
  const handleLetter = (e, newLetter) => {
    setState('');
    setLetter(newLetter);
    console.log(newLetter);
    let firstLetter = [];
    for (let i = 0; i < DrugData.length; i++) {
      if (DrugData[i].DrugName.startsWith(`${newLetter}`)) {
        firstLetter.push(DrugData[i].DrugName);
      }
    }
  };

  let filteredDrugs = [];
  for (let i = 0; i < DrugData.length; i++) {
    if (DrugData[i].DrugName.toLowerCase().startsWith(state.toLowerCase())) {
      filteredDrugs.push(DrugData[i].DrugName);
    }
  }
  console.log(filteredDrugs);
  const drugSet = new Set(filteredDrugs);
  const filteredDrugsSet = [...drugSet];

  let firstLetter = [];
  for (let i = 0; i < DrugData.length; i++) {
    if (DrugData[i].DrugName.startsWith(letter)) {
      firstLetter.push(DrugData[i].DrugName);
    }
  }

  // Remove duplicates
  const firstLetterSet = new Set(firstLetter);
  const firstLetterArray = [...firstLetterSet];
  // Sort array alphabetically
  const sortedFirstLetter = firstLetterArray.sort();
  console.log(sortedFirstLetter);

  let dropDownTherapy = [];
  for (let i = 0; i < DrugData.length; i++) {
    if (DrugData[i].TaxonomyName === therapy) {
      dropDownTherapy.push(DrugData[i].DrugName);
    }
  }
  console.log(dropDownTherapy);
  // Remove duplicates
  const dropDownSet = new Set(dropDownTherapy);
  const dropDownArray = [...dropDownSet];
  // Sort array alphabetically
  const sortedDropDown = dropDownArray.sort();
  console.log(sortedDropDown);

  // Displays list of drugs when letter is clicked based on therapy selected
  let filteredList = dropDownTherapy.filter((drug) => drug.startsWith(letter));
  // Remove duplicates
  const filteredListSet = new Set(filteredList);
  const filteredListArray = [...filteredListSet];
  // Sort array alphabetically
  const sortedFilteredList = filteredListArray.sort();
  console.log(sortedFilteredList)
  console.log(filteredList);

  function SearchDrugList() {
    if (state && filteredDrugsSet.length > 0) {
      return <DrugList filteredDrugs={filteredDrugsSet} />;
    } else if (state && filteredDrugsSet.length === 0) {
      return (
        <p style={{ fontSize: '1.5rem' }}>No drugs matching this criteria</p>
      );
    } else {
      return null;
    }
  }
  function CheckDrugList() {
    if (letter && !therapy) {
      return <DrugList filteredDrugs={sortedFirstLetter} />;
    } else if (letter && sortedFilteredList.length > 0) {
      return <DrugList filteredDrugs={sortedFilteredList} />;
    } else if (!letter && !therapy) {
      return <DrugList filteredDrugs={sortedDropDown} />;
    } else if (therapy && !letter) {
      return <DrugList filteredDrugs={sortedDropDown} />;
    } else {
      return (
        <p style={{ fontSize: '1.5rem' }}>No drugs matching this criteria</p>
      );
    }
  }

  console.log(filteredList);
  console.log(state);

  return <>
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            displayEmpty
            value={therapy}
            onChange={handleChange}
            onOpen={handleClear}
            style={{ backgroundColor: '#fff', width: '300px' }}
          >
            <MenuItem value="">
              <em style={{ color: 'gray' }}>Select a therapy type</em>
            </MenuItem>

            <MenuItem value="Allergy/Asthma">Allergy/Asthma</MenuItem>
            <MenuItem value="Cardiology">Cardiology</MenuItem>
            <MenuItem value="Dermatology">Dermatology</MenuItem>
            <MenuItem value="Endocrinology">Endocrinology</MenuItem>
            <MenuItem value="Gastroenterology">Gastroenterology</MenuItem>
            <MenuItem value="Immunology">Immunology</MenuItem>
            <MenuItem value="Infectious Disease">Infectious Disease</MenuItem>
            <MenuItem value="Internal Medicine">Internal Medicine</MenuItem>
            <MenuItem value="Oncology">Oncology</MenuItem>
            <MenuItem value="Ophthalmology">Ophthalmology</MenuItem>
            <MenuItem value="Orthopedic">Orthopedic</MenuItem>
            <MenuItem value="Pediatric Medicine">Pediatric Medicine</MenuItem>
            <MenuItem value="Psychiatry">Psychiatry</MenuItem>
            <MenuItem value="Pulmonary Disease">Pulmonary Disease</MenuItem>
            <MenuItem value="Rheumatology">Rheumatology</MenuItem>
            <MenuItem value="Specialty / Other">Specialty / Other</MenuItem>
            <MenuItem value="Transplant">Transplant</MenuItem>
            <MenuItem value="Urology">Urology</MenuItem>
            <MenuItem value="Womens Health">Women's Health</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          size="small"
          label="Search"
          value={state}
          onChange={handleInput}
          style={{ backgroundColor: '#fff', width: '400px' }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={handleClick}
          style={{ backgroundColor: '#2F89CC', color: '#fff' }}
        >
          Reset Search
        </Button>
      </Grid>
    </Grid>

    <Box width="80%" mt="80px" textAlign="center" margin="auto">
      <ToggleButtonGroup
        size="large"
        value={letter}
        exclusive
        onChange={handleLetter}
      >
        <ToggleButton
          className={classes.letterBox}
          value="A"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('A'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          A
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="B"
          variant="outlined"
          size="large"
          disableFocusRipple
          disableRipple
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('B'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          B
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="C"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('C'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          C
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="D"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('D'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          D
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="E"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('E'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          E
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="F"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('F'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          F
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="G"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('G'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          G
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="H"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('H'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          H
        </ToggleButton>

        <ToggleButton
          className={classes.letterBox}
          value="I"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('I'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          I
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="J"
          variant="outlined"
          size="large"
          style={{
            // marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('J'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          J
        </ToggleButton>
      </ToggleButtonGroup>
      <br />
      <br />
      <ToggleButtonGroup
        size="large"
        value={letter}
        exclusive
        onChange={handleLetter}
      >
        <ToggleButton
          className={classes.letterBox}
          value="K"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('K'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          K
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="L"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('L'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          L
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="M"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('M'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          M
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="N"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('N'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          N
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="O"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('O'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          O
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="P"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('P'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          P
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="Q"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('Q'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          Q
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="R"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('R'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          R
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="S"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('S'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          S
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="T"
          variant="outlined"
          size="large"
          style={{
            // marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('T'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          T
        </ToggleButton>
      </ToggleButtonGroup>
      <br />
      <br />
      <ToggleButtonGroup
        size="large"
        value={letter}
        exclusive
        onChange={handleLetter}
      >
        <ToggleButton
          className={classes.letterBox}
          value="U"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('U'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          U
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="V"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('V'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          V
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="W"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('W'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          W
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="X"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('X'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          X
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="Y"
          variant="outlined"
          size="large"
          style={{
            marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('Y'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          Y
        </ToggleButton>
        <ToggleButton
          className={classes.letterBox}
          value="Z"
          variant="outlined"
          size="large"
          style={{
            // marginRight: '25px',
            borderLeft: '2px solid #0088CC',
            fontSize: '2.2rem',
            fontWeight: 600,
          }}
          disabled={
            sortedDropDown.some((drug) => drug.startsWith('Z'))
              ? false
              : !letter && !therapy
              ? false
              : letter && !therapy
              ? false
              : true
          }
        >
          Z
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>

    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.container}
    >
      <Box width="60%" mt="20px" ml="300px" textAlign="left">
        <Box fontSize="5rem">{letter}</Box>

        <CheckDrugList />
        <SearchDrugList />
      </Box>
    </Grid>
  </>;
}

export default TherapySearch;
