import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import NewsItem from '../components/NewsItem';
import CTAButton from '../components/CTAButton';
import InfoFooter from '../components/InfoFooter';
import Footer from '../components/Footer';

const styles = {
    paperContainer: {
        backgroundImage: `url(https://cdn.rxlightning.com/images/website/banner/newsoverlay_opt.jpg)`,
        height: '500px',
        width: '100%',
        backgroundPosition: '10% 25%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: '#fff',
        textAlign: 'center',
        display: 'grid',
    },
};

function news() {
    return <>
        {/* Top section  */}
        <Box mt={4} style={styles.paperContainer} width="100%">
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xl={2} md={8}>
                    <Typography variant="h1">
                        <Box fontWeight="500" color="#fff" lineHeight="75px">
                            News | Press
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        {/* News section  */}
        <Box my={10} textAlign="center" mx="auto" width="90%">
            {/* Featured article  */}
            <Grid container justifyContent="center" textAlign="center">
                <Grid item md={10}>
                  <NewsItem
                    img="https://cdn.rxlightning.com/images/text_logo.png"
                    date="April 27, 2022"
                    title="RxLightning Awarded Tech Product of the Year and Startup of the Year at TechPoint’s “Best of Tech” Mira Awards"
                    desc="TechPoint, the nonprofit, industry-led growth initiative for Indiana’s technology ecosystem, held its 23rd annual Mira Awards program presented by Salesforce to celebrate the “The Best of Tech in Indiana”"
                    link="https://rxlightning.reportablenews.com/pr/rxlightning-awarded-tech-product-of-the-year-and-startup-of-the-year-at-techpoint-s-best-of-tech-mira-awards"
                    adjustedWidth={true}
                  />
                </Grid>
            </Grid>
            {/* Previous articles  */}
            <Box mt="80px">
                <Grid container alignItems="center" spacing={3}>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/TP-Mira-Winner-2022_Black-Gold.png"
                          date="April 26, 2022"
                          title="RxLightning wins Tech Product of the Year Mira Award"
                          desc="New Albany, Ind.-based RxLightning won the award for Tech Product of the Year during TechPoint’s 23rd annual Mira Awards gala honoring the best of tech in Indiana."
                          link="https://techpoint.org/company/rxlightning-wins-tech-product-of-the-year-mira-award/"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        {/* Replace img with tech point index dark logo */}
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/TP-Mira-Winner-2022_Black-Gold.png"
                          date="April 23, 2022"
                          title="RxLightning wins Startup of the Year Mira Award"
                          desc="RxLightning won the award for Startup of the Year during TechPoint’s 23rd annual Mira Awards gala honoring the best of tech in Indiana."
                          link="https://techpoint.org/2022/04/rxlightning-wins-startup-of-the-year-mira-award/"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="March 22, 2022"
                          title="Louisville area health tech company inks partnership to expand reach"
                          desc="Southern Indiana-based RxLightning has inked a new partnership with an prescribing platform, expanding the company’s reach."
                          link="https://www.bizjournals.com/louisville/news/2022/03/22/louisville-area-health-tech-company-inks-partnersh.html"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                            img="https://cdn.rxlightning.com/images/website/FDB_logo.jpg"
                            date="March 15, 2022"
                            title="FDB Launches FDB Vela™ – A New ePrescribing Network to Deliver Greater Choice and Value"
                            desc="Trusted leader in drug knowledge offers sought-after ePrescribing network option that empowers innovation, reduces barriers to participation, and increases cost-effectiveness. "
                            adjustedWidth={true}
                            />
                    </Grid>
                  <Grid item md={4}>
                      <NewsItem
                        img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                        date="December 13, 2021"
                        title="Here are the 22 startups you should be watching in 2022"
                        desc="RxLightning creating a perfect storm in the startup space this year."
                        link="https://www.bizjournals.com/louisville/news/2021/12/10/22-startups-to-watch-in-2022.html?ana=e_lou_bn_editorschoice_editorschoice&j=90576307&t=Breaking%20News&mkt_tok=NjczLVVXWS0yMjkAAAGBRMwzoNRabADgcfS6I2fLYhYiOgK3HSES-HUEvxJvQC3NQBUL_DLyftvKysY9X3oMxYYy8MQDyj7dnYJjTXPaqcOopKyaXtXjWy4_j_59AQvi47Xuff9Z"
                        adjustedWidth={true}
                        width="600px"
                      />
                  </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/text_logo.png"
                          date="September 14, 2021"
                          title="RxLightning Partners With Oncospark"
                          desc="To better serve patients and providers, Oncospark and RxLightning have joined together to provide a first-of-its-kind technology solution that addresses the major pain points of oncology medications."
                          link="https://rxlightning.reportablenews.com/pr/rxlightning-oncospark-announce-partnership-to-develop-unified-platform-that-streamlines-oncology-medication-enrollment-and-prior-authorization"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/microsoftrecordlogo.png"
                          date="July 8, 2021"
                          title="RxLightning's Julia Regan recognized by Microsoft's The Record as one of the women leading the change in healthcare innovation"
                          desc="Julia Regan, founder of RxLightning, is working to ensure that every patient gets accelerated access to the therapies they need by automating manual and arduous processes for prescribing and managing specialty medications."
                          link="https://www.technologyrecord.com/digital/magazine/issue21/138/index.html"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/medcitynews.png"
                          date="June 16, 2021"
                          title="RxLightning raises $3M to help providers streamline specialty drug enrollment"
                          desc="The health technology company offers a platform that enables clinicians to more quickly and accurately complete specialty drug enrollment processes."
                          link="https://medcitynews.com/2021/06/rxlightning-raises-3m-to-help-providers-streamline-specialty-drug-enrollment/"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/text_logo.png"
                          date="June 16, 2021"
                          title="RxLightning announces addition of new board members following $3 million seed round led by Health X Ventures"
                          desc="Health-tech startup adds HealthX General Partner Taha Jangda and former Walgreens, Aetna, Anthem executive Brad Fluegel to Board amid rapid industry adoption of first-of-its-kind digital platform for specialty drug enrollment."
                          link="https://cdn.rxlightning.com/documents/Rxlightning-Press-6.16.21.pdf"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="April 26, 2021"
                          title="RxLightning raises $2M with HealthX Ventures leading the round"
                          desc="The company's co-founder and CEO didn't actively seek out the new funding. But it came to company after gaining exposure through two well-known startup programs."
                          link="https://www.bizjournals.com/louisville/news/2021/04/26/healthx-ventures-invests-in-2-million-round-rxli.html?ana=e_lou_bn_editorschoice_editorschoice&j=90558158&t=Breaking%20News&mkt_tok=NjczLVVXWS0yMjkAAAF8sepzM9pPDqp-FdW9U76p8teqa6cgG1Byn79qaEz7SS4g3l5qT5bGF13P7135SifVkM1YbW_eAKPQbLBiTAej8JFhOuUt1pToRHgW_jUx8Z08UsL3DoQ"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/team/elizabethjohnson.png"
                          date="April 20, 2021"
                          title="RxLightning Taps Patient Advocate and NAMAPA Co-Founder as Director of Product Management"
                          desc="RxLightning, a healthcare technology company that simplifies and automates the complex specialty medication enrollment process through a single comprehensive..."
                          link="https://cdn.rxlightning.com/documents/RxLightningElizabethJohnson.pdf"
                          adjustedWidth={false}
                          width="200px"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="March 26, 2021"
                          title="Louisville area startup accepted to exclusive Microsoft program"
                          desc="A program run by the technology titan Microsoft Inc. selected New Albany, Indiana-based RxLightning LLC to participate in a relatively exclusive startup support program."
                          link="https://www.bizjournals.com/louisville/news/2021/03/26/rxlightning-selected-for-microsoft-for-startups.html"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/microsoft-startup.jpg"
                          date="March 25, 2021"
                          title="RxLightning Selected for Microsoft for Startups Program"
                          desc="RxLightning, a healthcare technology company that simplifies and automates the complex specialty medication enrollment process through a single comprehensive..."
                          link="https://cdn.rxlightning.com/documents/RxLightningMicrosoftPress.pdf"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/text_logo.png"
                          date="March 18, 2021"
                          title="RxLightning Launches Revolutionary Specialty Drug Enrollment Platform Guaranteed to Save Time, Ease Provider Burden"
                          desc="Free tool to enroll patients on specialty medications faster addresses historically complicated manual enrollment process through its digitized, automated platform."
                          link="https://cdn.rxlightning.com/documents/RxLightning-Press-3.18.21.pdf"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/endeavor-logo.png"
                          date="March 8, 2021"
                          title="23 Female Entrepreneurs In The Midwest That Need To Be On Your Radar"
                          desc="It’s no secret that women have long existed in a business ecosystem designed with men in mind. Now, thanks to advances in technology, profound societal and cultural shifts, and the inevitable triumph of pure talent, women are right where they deserve to be: in the corner office."
                          link="https://endeavor-ky-in-oh.org/blog/female-entrepreneurs-in-the-midwest/?utm_source=mailchimp&utm_medium=email&utm_campaign=gnnl_0310"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="February 9, 2021"
                          title="RxLightning launches partnership with IllumiCare"
                          desc="Louisville-based RxLightning has struck a partnership that will put its technology in the hands of providers at 150 hospitals across the country."
                          link="https://www.bizjournals.com/louisville/news/2021/02/09/rxlighting-illumicare-launch-partnership.html"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/illumicarelogo.png"
                          date="February 2, 2021"
                          title="IllumiCare and RxLightning Partner to Increase Prescribing Efficiency of Specialty Medications"
                          desc="IllumiCare, a leader in point-of-care technology, has partnered with RxLightning, a digital solution for simplifying the manual enrollment process of prescribing specialty medications, to provide healthcare providers with a streamlined approach to prescribing, ordering and tracking specialty medications within Electronic Health Records (EHR)."
                          link="https://www.rxlightning.com/media/IllumiCare%20+%20RxLightning%20Announce%20Partnership%20to%20Drive%20Specialty%20Medication%20Prescribing%20Efficacy_2.2.21.pdf"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="January 15, 2021"
                          title="RxLightning Recognized as the Fastest Growing Company in Louisville in 2021"
                          desc="Business First has named the startups to watch in 2021 with the help of area investors, advisors and community organizers."
                          link="https://www.bizjournals.com/louisville/news/2021/01/15/louisville-area-startups-to-watch-in-2021.html"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/venturenash.png"
                          date="December 4, 2020"
                          title="Venture Nashville Highlights Kentucky Startup RxLightning"
                          desc='RxLightning Inc., led by Founder-CEO Julia Crouse Regan, says it "digitizes, automates and streamlines the historically complicated manual enrollment process of starting a patient on specialty medications."'
                          link="https://www.venturenashville.com/venture-notes---kentucky-ventures---december-4-2020-cms-2042"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lbf-logo.png"
                          date="October 5, 2020"
                          title="RxLightning's Julia Regan shares fundraising strategy during Covid-19"
                          desc="RxLightning, a member of the XLerateHeath and Vogt Awards cohorts, just closed on a $1 million raise in August that turned into a seed round with 11 investors."
                          link="https://www.bizjournals.com/louisville/news/2020/10/05/rxlightnings-julia-regan-shares-fundraising-strat.html?ana=e_me_prem&j=90531337&t=Morning&mkt_tok=eyJpIjoiTnpJMU9HVTVPVFk0T0RabCIsInQiOiJQN3ZYMkJaNkM2TE9mdVc2XC9lc3huSmluYnJCOGphbmwrTThOTXFkQXpCc2hlQm1ITXR0NXVGOXA5N2s2OThCbHYxaDc0VGFzY2NjYzRYbGZzSWxQQVVPbmt5T0hLeFJFdFdEcWo0d0srU3A2eThUaXVNT1lSWXo4ZTdVbmUxRkI5alFoTUNDU2JxcXNaRVdoNnR1aWF3PT0ifQ%3D%3D"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/vogt-logo.png"
                          date="August 5, 2020"
                          title="Six Louisville startup companies have been selected as Vogt Award winners during the program's 20th anniversary year"
                          desc="Each early-stage company will receive $25,000 in non-dilutive grant funding, participation in a 10-week lean startup education program, coaching from scalable startup CEOs, industry mentorship and strategic introductions."
                          link="https://www.bizjournals.com/louisville/news/2020/08/05/six-louisville-startups-selected-as-vogt-award-win.html"
                          adjustedHeight={false}
                          height="120px"
                          adjustedWidth={false}
                          width="120px"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/cfllogo.png"
                          date="August 5, 2020"
                          title="Six Louisville Startups Win 2020 Vogt Awards"
                          desc="The Community Foundation of Louisville is proud to announce the winners of the 20th annual Vogt Awards."
                          link="https://www.cflouisville.org/six-louisville-startups-win-2020-vogt-awards/"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/businesswirelogo.png"
                          date="August 4, 2020"
                          title="XLerateHealth Selects Seven Healthcare Startups for 2020 Accelerator Cohort in Louisville, KY"
                          desc="XLerateHealth (XLH), a nationally recognized healthcare accelerator headquartered in Louisville, today announced the selection of seven startup companies to participate in its 10th cohort."
                          link="https://www.businesswire.com/news/home/20200804005091/en/XLerateHealth-Selects-Healthcare-Startups-2020-Accelerator-Cohort"
                          adjustedWidth={true}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/lanereportlogo.jpeg"
                          date="August 4, 2020"
                          title="XLerateHealth selects 7 startups for 2020 accelerator cohort in Louisville"
                          desc="XLerateHealth (XLH), a nationally recognized healthcare accelerator headquartered in Louisville, announced Tuesday the selection of seven startup companies to participate in its 10th cohort."
                          link="https://www.lanereport.com/129224/2020/08/xleratehealth-selects-7-startups-for-2020-accelerator-cohort-in-louisville/"
                          adjustedHeight={false}
                          height="200px"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <NewsItem
                          img="https://cdn.rxlightning.com/images/website/logos/nasplogo.jpg"
                          date="July 31, 2020"
                          title="Startup RxLightning Selected as Finalist for NASP Challenge"
                          desc="RxLightning has been selected as a NASP Challenge finalist and will compete live at the NASP Annual Meeting & Expo in Washington, DC!"
                          link="https://medstro.com/posts/22166"
                          adjustedWidth={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <CTAButton />
        <InfoFooter />
        <Footer />
    </>;
}

export default news;
