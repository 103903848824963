import React, {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/home';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {ThemeProvider} from '@mui/styles';
import theme from './theme';
import NavBar from './components/NavBar';
import About from './pages/about';
import Solution from './pages/solution';
import Contact from './pages/contact';
import News from './pages/news';
import Therapies from './pages/therapies';
import PatientReg from './pages/patientreg';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import ScrollToTop from './components/ScrollToTop';
import './components/Overflow.css';
import {positions, Provider} from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga';
import {Button, Hidden} from '@mui/material';

const options = {
  position: positions.MIDDLE,
};

function App() {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(true);

  useEffect(() => {
    ReactGA.initialize('UA-175838549-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <ScrollToTop />
        <ThemeProvider theme={theme}>
          <Provider template={AlertMUITemplate} {...options}>
            <CssBaseline />
            <CookieConsent
              style={{ display: show ? 'none' : 'flex' }}
              buttonStyle={{
                backgroundColor: '#2F89CC',
                color: '#fff',
                fontWeight: 600,
              }}
            >
              RxLightning uses cookies to enhance your experience. We use these
              for analytics purposes. By continuing to use our site, you agree to
              our use of cookies.{' '}
              <a
                href="https://cdn.rxlightning.com/documents/RxLightning-Terms-of-Use_v1.pdf"
                style={{ textDecoration: 'none', color: '#2f89cc' }}
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
              <Hidden smUp>
                <Button
                  onClick={handleClick}
                  style={{
                    color: '#fff',
                    border: '1px solid #fff',
                    marginTop: '10px',
                  }}
                >
                  Close
                </Button>
              </Hidden>
            </CookieConsent>
            <NavBar />
            <Switch>
              {process.env.REACT_APP_ENV === 'dev' &&
              <Route path="/patientenrollment" component={PatientReg} />
            }
              <Route path="/" exact component={Home} />
              <Redirect from="/index.html" to="/" />
              <Route path="/about" component={About} />
              <Redirect from="/about.html" to="/about" />
              <Route path="/solution" component={Solution} />
              <Route path="/contact" component={Contact} />
              <Redirect from="/contact.html" to="/contact" />
              <Route path="/news" component={News} />
              <Redirect from="/news.html" to="/news" />
              <Route path="/therapies" component={Therapies} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Redirect to="/" />
            </Switch>
          </Provider>
        </ThemeProvider>
    </Router>
  );
}

export default App;
