import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

makeStyles({
  root: {
    maxWidth: 700,
  },
  media: {
    height: 200,
  },
});

function NewsItem(props) {
  const adjustedHeight = props.adjustedHeight;
  const adjustedWidth = props.adjustedWidth;

  return (
    <div>
      <Box>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item md={10}>
            <img
              src={props.img}
              alt={props.alt}
              width={adjustedWidth ? '100%' : props.width}
              height={adjustedHeight ? '100px' : props.height}
            />
          </Grid>
          <Grid item>
            {/* Article info  */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={10}>
                <Box fontSize="1.5rem" fontWeight="500" mt={3}>
                  {props.title}
                </Box>
              </Grid>
              <Grid item md={10}>
                <p style={{ fontSize: '1.2rem' }}>{props.desc}</p>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  <a
                    href={props.link}
                    style={{ textDecoration: 'none', fontSize: '1.2rem' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Box fontSize="1rem">{props.date}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default NewsItem;
