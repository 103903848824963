import React from 'react';
import {Box, Grid} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

function Checklist(props) {
  const fourChecks = props.fourChecks;
  const threeChecks = props.threeChecks;
  return (
    <>
      <Box fontWeight="fontWeightMedium" fontSize="1.15rem">
        <Grid container>
          <Box width={1}>
            <Grid container alignItems="flex-start">
              <Grid item md={1} xs={1}>
                <CheckIcon color="primary" />
              </Grid>
              <Grid item md={10} xs={10}>
                <b style={{ color: props.boldColor, fontSize: '1.3rem' }}>
                  {props.bold1}
                </b>
                <b style={{ fontWeight: 400, fontSize: '1.3rem' }}>
                  {props.item1}
                </b>
              </Grid>
            </Grid>
          </Box>

          <Box width={1} mt={1}>
            <Grid container>
              <Grid item md={1} xs={1}>
                <CheckIcon color="primary" />
              </Grid>
              <Grid item md={10} xs={10}>
                <b style={{ color: props.boldColor, fontSize: '1.3rem' }}>
                  {props.bold2}
                </b>
                <b style={{ fontWeight: 400, fontSize: '1.3rem' }}>
                  {props.item2}
                </b>
              </Grid>
            </Grid>
          </Box>

          {threeChecks ? (
            <Box width={1} mt={1}>
              <Grid container>
                <Grid item md={1} xs={1}>
                  <CheckIcon color="primary" />
                </Grid>
                <Grid item md={10} xs={10}>
                  <b style={{ color: props.boldColor, fontSize: '1.3rem' }}>
                    {props.bold3}
                  </b>
                  <b style={{ fontWeight: 400, fontSize: '1.3rem' }}>
                    {props.item3}
                  </b>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ''
          )}

          {fourChecks ? (
            <>
              <Box width={1} mt={1}>
                <Grid container>
                  <Grid item md={1} xs={1}>
                    <CheckIcon color="primary" />
                  </Grid>
                  <Grid item md={10} xs={10}>
                    <b style={{ color: props.boldColor, fontSize: '1.3rem' }}>
                      {props.bold3}
                    </b>
                    <b style={{ fontWeight: 400, fontSize: '1.3rem' }}>
                      {props.item3}
                    </b>
                  </Grid>
                </Grid>
              </Box>

              <Box width={1} mt={1}>
                <Grid container>
                  <Grid item md={1} xs={1}>
                    <CheckIcon color="primary" />
                  </Grid>
                  <Grid item md={10} xs={10}>
                    <b style={{ color: props.boldColor, fontSize: '1.3rem' }}>
                      {props.bold4}
                    </b>
                    <b style={{ fontWeight: 400, fontSize: '1.3rem' }}>
                      {props.item4}
                    </b>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </>
  );
}

export default Checklist;
